
import {
    Container, Card, CardBody,
    CardContent, TextBox, TextBoxBigTitle,
    CardParagraph1, Button, Row, Col,
    TextBoxSubTitle, CardMedia, TextBoxBiggerTitle, detectDevice
} from "@sberdevices/plasma-ui";
import React from "react";
import { sendText } from "../../helpers/Assistant";
import { formatTimeToDraw, formatMoney } from "../../helpers/TextUtils";
import { StolotoTabs } from "../StolotoTabs"
import { AssistantContext } from "../../helpers/AssistantContext";
import {
    ShowTicketCheckResultWinLooseTitle, ShowTicketCheckResulCol, ShowTicketCheckResulMainContainer, ShowTicketCheckResulContainer,
    ShowTicketCheckResulSmallHeaderText, ShowTicketCheckResulHeaderText, ShowTicketCheckResultUnplayedNumbersTitle,
    ShowTicketCheckResultMyTicketButton, ShowTicketCheckResultNumberTicketTitle, ShowTicketCheckResultTextBoxBigTitle
} from './styles/tickets-results-styles'
class ShowTicketCheckResult extends React.Component {

    static contextType = AssistantContext;
    constructor(props) {
        super(props);
        this.state = {
            ticketInfo: this.getTicketData(),

        }
        window.disallowedDirections=null
        this.initial_focus = null;
    }

    

    getWinningCombinationKenoFirstGame() {
        const parities = { 'ODD': 'Нечетные', 'EVEN': 'Четные', 'FIFTY_FIFTY': 'Поровну' }

        const currentParity = !this.props.offline ? this.props.ticketsInfo.results[0]?.drawInfo?.combination?.structured?.parity : this.props.ticketsInfo.results[0].draws[0]?.draw.combination?.structured?.parity
        return currentParity ? parities[currentParity] : null
    }

    getWinningCombinationKenoThirdGame() {
        return !this.props.offline ? this.props.ticketsInfo.results[0].drawInfo?.combination?.structured?.column : this.props.ticketsInfo.results[0].draws[0]?.draw.combination?.structured?.column
    }

    getWinningCombinations(winningCombination) {
        return winningCombination ? winningCombination.length > 0 ? winningCombination.toString().replace(/[, ]+/g, " ").trim() : '' : ''
    }
    getTicketData() {
        let ticketInfo = {}
        console.log(this.props.ticketsInfo)
        if (this.props.onlineTicket) {
            ticketInfo = {
                'winStatus': this.props.ticketsInfo.win,
                'prize': this.props.ticketsInfo.results[0].prize,
                'edition': this.props.ticketsInfo.results[0].drawInfo.number,
                'ticketNumber': this.props.ticketsInfo.results[0].displayNumber,
                'data': this.props.ticketsInfo.results[0].date,
                'numbersNotInGame': this.getWinningCombinations(this.props.ticketsInfo.results[0].drawInfo.winningCombination),
                'kenoFirstGame': this.getWinningCombinationKenoFirstGame(),
                'kenoThirdGame': this.getWinningCombinationKenoThirdGame(),
            }
        } else {
            ticketInfo = {
                'winStatus': this.props.ticketsInfo.results[0].win,
                'prize': this.props.ticketsInfo.results[0].draws[0]?.prize,
                'edition': this.props.ticketsInfo.results[0].draws[0]?.draw.number,
                'ticketNumber': this.props.ticketsInfo.results[0].ticketNumber,
                'data': this.props.ticketsInfo.results[0].draws[0]?.draw.date,
                'numbersNotInGame': this.getWinningCombinations(this.props.ticketsInfo.results[0].draws[0]?.draw.winningCombination),
                'kenoFirstGame': this.getWinningCombinationKenoFirstGame(),
                'kenoThirdGame': this.getWinningCombinationKenoThirdGame(),
            }
        }
        return ticketInfo
    }

    getWinOrLoseText() {
        const text = 'Нет выигрыша'
        const win = this.state.ticketInfo.winStatus
        return <ShowTicketCheckResultWinLooseTitle marginLeft={detectDevice() == 'mobile' && '-5px'} win={this.state.ticketInfo.winStatus}>
            {win ? formatMoney(this.state.ticketInfo.prize) : text}
        </ShowTicketCheckResultWinLooseTitle>
    }

    getMyTicketButton() {
        return <ShowTicketCheckResultMyTicketButton 
                scaleOnInteraction={false} 
                tabIndex={this.props.messagePopupWasOpened ? -1 : 0} 
                size='s' 
                onClick={() => sendText(this.context, 'Мой билет')}>
                    Мой билет
                </ShowTicketCheckResultMyTicketButton>
    }

    getUnplayedNumbers() {
        return this.state.ticketInfo.numbersNotInGame && <ShowTicketCheckResultUnplayedNumbersTitle >{this.state.ticketInfo.numbersNotInGame}</ShowTicketCheckResultUnplayedNumbersTitle>
    }

    getData() {
        const drawTime = new Date(this.state.ticketInfo.data)
        const fullYear = drawTime.getFullYear()
        const fullDate = drawTime.getDate() <= 10 ? `0${drawTime.getDate()}` : drawTime.getDate()
        const fullMonth = drawTime.getMonth()+1 <= 10 ? `0${drawTime.getMonth()+1}` : drawTime.getMonth() + 1
        const data = `${fullDate}.${fullMonth}.${fullYear}`
        return <ShowTicketCheckResultNumberTicketTitle marginLeftMobile={'-2px'} marginLeft={'-5px'}>{this.state.ticketInfo.data ? data : null}</ShowTicketCheckResultNumberTicketTitle>
    }

    sendTextActivityButton() {
        if (this.state.ticketInfo.winStatus) {
            sendText(this.context, 'Как получить выигрыш?')
        } else {
            sendText(this.context, 'Участвовать снова')
        }
    }

    getActivityButton() {
        const actButtonText = 'Участвовать снова'
        const howGetPrize = 'Как получить выигрыш? '
        return <ShowTicketCheckResultMyTicketButton
        
            scaleOnInteraction={false}
            ref={(card) => { if (!this.initial_focus) { this.initial_focus = card } }}
            tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
            firstButton={true} size={'s'}
            view="success"
            onClick={() => this.sendTextActivityButton()}
        >
            {this.state.ticketInfo.winStatus ? howGetPrize : actButtonText}
        </ShowTicketCheckResultMyTicketButton>
    }

    getEditionInfo() {
        return <ShowTicketCheckResultNumberTicketTitle marginLeftMobile={detectDevice() == 'mobile' && '-2px'} marginLeft={'-4px'}>{this.state.ticketInfo.edition}</ShowTicketCheckResultNumberTicketTitle>
    }

    getTicketNumber() {
        return <ShowTicketCheckResultNumberTicketTitle marginLeftMobile={detectDevice() == 'mobile' && '-2px'} marginLeft={'-3px'}>{this.state.ticketInfo.ticketNumber}</ShowTicketCheckResultNumberTicketTitle>
    }
    getFirstKenoGame() {
        return (this.state.ticketInfo.kenoFirstGame ?
            <> <Row>
                <Col><ShowTicketCheckResulSmallHeaderText marginLeft={'-2px'}>Выпавшая чётность</ShowTicketCheckResulSmallHeaderText> </Col>
            </Row>

                <Row>
                    <Col><ShowTicketCheckResultUnplayedNumbersTitle>{this.state.ticketInfo.kenoFirstGame}</ShowTicketCheckResultUnplayedNumbersTitle></Col>
                </Row></>
            : null)
    }
    getThirdKenoGame() {
        return (
            this.state.ticketInfo.kenoThirdGame ?
                <>
                    <Row>
                        <Col><ShowTicketCheckResulSmallHeaderText marginLeft={'-2px'}>Выпавший столбец</ShowTicketCheckResulSmallHeaderText> </Col>
                    </Row>
                    <Row>
                        <Col><ShowTicketCheckResultUnplayedNumbersTitle >{this.state.ticketInfo.kenoThirdGame}</ShowTicketCheckResultUnplayedNumbersTitle></Col>
                    </Row>
                </> : null
        )
    }
    getSpecialStylesTabs() {
        return { position: detectDevice()!='mobile' && 'sticky', background: '#00000000' }
    }
    setBackGroundIfWin() {

        if (this.state.ticketInfo.winStatus) {
            document.getElementsByTagName('body')[0].setAttribute(
                'style',
                `
                    background-image: url(/main_images/background_win_${detectDevice()}.png); 
                    background-position: center center; 
                    background-repeat: no-repeat;background-size: cover;
                `
            )
        }
    }
    componentDidMount() {
        
          this.initial_focus.focus();
        
          
      }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].style.backgroundImage = null
    }

    render() {
        console.log(!this.props.offline)
        this.setBackGroundIfWin()
        return (
            <ShowTicketCheckResulMainContainer id='bibs'>

                <StolotoTabs
                    messagePopupWasOpened={this.props.messagePopupWasOpened}
                    isShowResultPage={true}
                    position= {detectDevice()!='mobile' && 'sticky'}
                    specialStyles={this.getSpecialStylesTabs()}
                    activeTab={!this.props.offline ? 'my_tickets':'check_tickets' }
                    messageShow={this.props.messageShow}
                />

                <ShowTicketCheckResulContainer>
                    <Row style={{ justifyContent: 'center', margin: 'auto', flexWrap: 'no-wrap' }}>
                        <Col style={detectDevice() == 'mobile' ? { width: '100%', borderBottom: '1px solid rgba(255,255,255,0.2)', } : null}>
                            <Card style={{ background: '#00000000' }} >
                                <CardBody style={{ background: '#00000000' }} >
                                    <CardContent style={{ background: '#00000000', paddingTop: detectDevice() == 'mobile' && '0px', paddingBottom: detectDevice() == 'mobile' && '15px' }} >
                                        <TextBox>
                                            <ShowTicketCheckResulHeaderText marginLeft={detectDevice() == 'mobile' && '-4px'}>Результат</ShowTicketCheckResulHeaderText>
                                            {this.state.ticketInfo.winStatus && <ShowTicketCheckResulSmallHeaderText marginLeft={detectDevice() == 'mobile' && '-2px'}>Выигрыш</ShowTicketCheckResulSmallHeaderText>}
                                            {this.getWinOrLoseText()}
                                        </TextBox>

                                        {detectDevice() !== 'mobile' ? this.getActivityButton() : null}
                                        {detectDevice() !== 'mobile' && !this.props.offline ? this.getMyTicketButton() : null}
                                    </CardContent>
                                </CardBody>
                            </Card>
                        </Col>
                        {
                            this.state.ticketInfo.data ?
                                <Col style={detectDevice() == 'mobile' ? { width: '100%', paddingTop: '5px', paddingBottom: '3px' } : null} >
                                    <Card style={{ background: '#00000000', }}>
                                        <CardBody>
                                            <CardContent style={{ paddingTop: detectDevice() == 'mobile' && '5px', }}>
                                                <TextBox>
                                                    <ShowTicketCheckResultTextBoxBigTitle marginLeft={detectDevice()=='sberPortal' && '-5px'}>Ваш билет</ShowTicketCheckResultTextBoxBigTitle>
                                                    {
                                                        detectDevice() == 'mobile' ?
                                                            <Col style={{ marginLeft: '0px' }}>
                                                                <Row>

                                                                    {
                                                                        this.state.ticketInfo.edition &&
                                                                        <Col style={{ marginRight: '1em' }}>
                                                                            <Row><ShowTicketCheckResulSmallHeaderText marginLeft={detectDevice() == 'mobile' && '-2px'}>Тираж</ShowTicketCheckResulSmallHeaderText> </Row>
                                                                            <Row>{this.getEditionInfo()} </Row>

                                                                        </Col>
                                                                    }
                                                                    <Col style={{ marginLeft: this.state.ticketInfo.edition && '0.5em' }}>
                                                                        <Row><ShowTicketCheckResulSmallHeaderText marginLeft={detectDevice() == 'mobile' && '-2px'} >Дата </ShowTicketCheckResulSmallHeaderText> </Row>
                                                                        <Row>{this.getData()} </Row>

                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col >
                                                                        <Row> <ShowTicketCheckResulSmallHeaderText marginLeft={detectDevice() == 'mobile' && '-2px'}>Билет № </ShowTicketCheckResulSmallHeaderText></Row>
                                                                        <Row>{this.getTicketNumber()} </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col> :
                                                            <Col style={{ marginLeft: '0px' }}>
                                                                {
                                                                    this.state.ticketInfo.edition &&
                                                                    <Row >
                                                                        <Col>
                                                                            <Row><ShowTicketCheckResulSmallHeaderText marginLeft={detectDevice() == 'mobile' && '-2px'}>Тираж</ShowTicketCheckResulSmallHeaderText> </Row>
                                                                            <Row>{this.getEditionInfo()} </Row>
                                                                        </Col>


                                                                    </Row>
                                                                }
                                                                <Row>
                                                                    <Col >
                                                                        <Row><ShowTicketCheckResulSmallHeaderText marginLeft={detectDevice() == 'mobile' && '2px'} >Дата </ShowTicketCheckResulSmallHeaderText> </Row>
                                                                        <Row>{this.getData()} </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row >
                                                                    <Col>
                                                                        <Row> <ShowTicketCheckResulSmallHeaderText>Билет № </ShowTicketCheckResulSmallHeaderText></Row>
                                                                        <Row>{this.getTicketNumber()} </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                    }

                                                    {/* <Row style={detectDevice() != 'mobile' ? { display: 'contents' } : { display: 'flex' }}>
                                            <Col >
                                                    <Row><ShowTicketCheckResulSmallHeaderText marginLeft={detectDevice()=='mobile' && '2px'} >Дата </ShowTicketCheckResulSmallHeaderText> </Row>
                                                    <Row>{this.getData()} </Row>
                                                </Col>

                                        </Row> */}
                                                    {/* <Row>
                                            <Col><ShowTicketCheckResulSmallHeaderText marginLeft={'-2px'}>Выпавшие числа</ShowTicketCheckResulSmallHeaderText> </Col>
                                        </Row>
                                        <Row>
                                            <ShowTicketCheckResulCol>{this.getUnplayedNumbers()} </ShowTicketCheckResulCol>
                                        </Row> */}
                                                    {/* {this.getFirstKenoGame()}
                                        {this.getThirdKenoGame()} */}
                                                </TextBox>

                                                {detectDevice() == 'mobile' ? this.getActivityButton() : null}
                                                {detectDevice() == 'mobile' && !this.props.offline ? this.getMyTicketButton() : null}
                                            </CardContent>
                                        </CardBody>
                                    </Card>
                                </Col> : null
                        }

                    </Row>
                </ShowTicketCheckResulContainer>
            </ShowTicketCheckResulMainContainer>
        );
    }
}


export default ShowTicketCheckResult;
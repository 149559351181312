
import { Button } from "@sberdevices/plasma-ui";
import React from "react";
import { BUTTON_STYLES, TABLE_STYLES } from "../../constants"
import { detectDevice } from '@sberdevices/plasma-ui';
import {WhiteSpaceTr,StyledParagraphText2TicketBarcode, GreenCross, GreenCrossImg } from './styles/bingo-styles'
import { IconClose } from '@sberdevices/plasma-icons';
import { AssistantContext } from "../../../helpers/AssistantContext"
class BingoTicket extends React.Component {

    constructor(props) {
        super(props);
    }


    getTd(content, col) {
        const deviceType = detectDevice()

        const buttonSizeStyles = BUTTON_STYLES[this.props.bingoName][deviceType]
        const style = {
            opacity: (content.value == '' && ['rocketbingo', 'bingo75'].includes(this.props.bingoName)) ? '0' : '1',
            cursor: 'pointer',
            color: content.value == '' ? 'rgba(0,0,0,0)' : this.props.buttonColor,
            backgroundColor: this.props.buttonColor == 'black' ? 'rgba(0,0,0,0.12)' : 'rgba(255,255,255,0.12)',
            fontSize: buttonSizeStyles.fontSize,
            height: buttonSizeStyles.height,
            width: buttonSizeStyles.width,
            borderRadius: buttonSizeStyles.borderRadius,
            margin: '1px',
            fontWeight: '400',
        }

        if (content.active) {
            return <td key={col}> <div style={{ display: 'flex', padding: buttonSizeStyles.buttonPadding }}><Button scaleOnInteraction={false} id={this.props.touchIndex} touchIndex={this.props.touchIndex} tabIndex={-1} style={style} size='s' square >{content.value !== '' ? content.value : 99}</Button></div> </td>
        } else {
            return <td key={col}> <div style={{ display: 'flex', padding: buttonSizeStyles.buttonPadding }}> <Button scaleOnInteraction={false} id={this.props.touchIndex} touchIndex={this.props.touchIndex} tabIndex={-1} style={style} disabled={true} size='s' square >{content.value}<GreenCross><GreenCrossImg src={process.env.PUBLIC_URL + 'cross.svg'}/></GreenCross></Button></div></td>
        }
    }

    build6x36() {
        let numbers = this.props.numbers
        let tr = []

        for (let row = 0; row < 6; row++) {
            let td = []
            for (let col = 0; col < 6; col++) {
                let currentNumber = ((col + 1) + (row * 6))
                const contentValue = { active: !numbers.includes(currentNumber), value: currentNumber }
                td.push(this.getTd(contentValue, col))
            }
            tr.push(<tr key={row}>{td}</tr>)
        }
        return tr
    }

    build9x3() {
        let numbers = this.props.numbers
        let tr = []
        for (let row = 0; row < 6; row++) {
            let chanked = numbers.slice(row * 5, (row * 5) + 5)
            let td = []
            for (let col = 0; col < 9; col++) {
                let target = null
                for (let chank in chanked) {
                    if (((col + 1) * 10) >= chanked[chank]) {
                        target = chanked[chank]
                        chanked.splice(chank, 1);
                    }
                }
                const contentValue = { active: true, value: target || '' }
                td.push(this.getTd(contentValue))
            }
            tr.push(<tr>{td}</tr>)
        }
        const dash = Array.from({ length: 9 }, (_, i) => <td style={this.props.buttonColor=='black'? {backgroundColor:'rgba(0, 0, 0, 0.12)'} :{ backgroundColor: 'rgba(255, 255, 255, 0.12)' }}></td>)
        const whiteSpace = Array.from({ length: 9 }, (_, i) => <td></td>)

        tr.splice(3, 0, <WhiteSpaceTr>{whiteSpace}</WhiteSpaceTr>);
        tr.splice(4, 0, <tr>{dash}</tr>);
        tr.splice(5, 0, <WhiteSpaceTr>{whiteSpace}</WhiteSpaceTr>);

        return tr
    }

    build5x5() {
        let numbers = this.props.numbers
        let newNumbersArray = numbers.slice(0, 12)
        newNumbersArray = newNumbersArray.concat([0]).concat(numbers)
        let tr = []
        for (let row = 0; row < 5; row++) {
            let td = []
            for (let col = 0; col < 5; col++) {
                let currentValue = newNumbersArray[(col + (row * 5))]
                if (currentValue !== undefined) {
                    const contentValue = { active: true, value: currentValue || '' }
                    td.push(this.getTd(contentValue))
                }
            }
            tr.push(<tr>{td}</tr>)
        }
        return tr
    }

    getGameTicket(bingoName) {
        if (bingoName === '6x36') {
            return this.build6x36()
        }
        if (['zp', 'gzhl', 'ruslotto', 'express'].includes(bingoName)) {
            return this.build9x3()
        }
        if (['rocketbingo', 'bingo75'].includes(bingoName)) {
            return this.build5x5()
        }
    }

    getStylesParagraph(){
        if(detectDevice()=='sberBox'){
            if(['rocketbingo','bingo75'].includes(this.props.bingoName)){
                return {paddingLeft: '13px'}
            }
        }
    }

    render() {
        const currentGameName = this.props.bingoName
        return (
            <>
                <StyledParagraphText2TicketBarcode style={this.getStylesParagraph()} colorText={this.props.buttonColor} >{'БИЛЕТ №' + this.props.ticketBarcode}</StyledParagraphText2TicketBarcode>
                <table style={{
                    marginLeft: TABLE_STYLES[detectDevice()]?.[currentGameName]?.marginLeft,
                    marginBottom: TABLE_STYLES[detectDevice()]?.[currentGameName]?.marginBottom,
                    marginRight: TABLE_STYLES[detectDevice()]?.[currentGameName]?.marginRight,
                    marginTop: TABLE_STYLES[detectDevice()]?.[currentGameName]?.marginTop,
                    borderCollapse: 'collapse',
                }}>

                    <tbody>
                        {this.getGameTicket(this.props.bingoName)}
                    </tbody>
                </table>

            </>
        );
    }
}

const BingoTicketMemo = React.memo(BingoTicket);

export default BingoTicketMemo;
import { Assistant, sendAction, sendText } from "./Assistant";

type SpatialNavigationDirection = "up" | "down" | "left" | "right";

type JoinedStr<S extends string, Sep extends string> = `${S}` | `${S}${Sep}${S}` | `${S}${Sep}${S}${Sep}${S}` | `${S}${Sep}${S}${Sep}${S}${Sep}${S}`

type NavigationDirectionList = JoinedStr<SpatialNavigationDirection, ','>

interface NavigationEvent extends UIEvent {
    detail: number & {
        causedTarget: Element,
        dir: SpatialNavigationDirection
    }
};

export const getDisallowedDirections = (index: number, length: number): NavigationDirectionList | undefined => {
    const disallowed: SpatialNavigationDirection[] = [];
    if (index === 0) {
        disallowed.push('left')
    }
    if (index === length - 1) {
        disallowed.push('right')
    }
    if (!disallowed.length) {
        return undefined;
    }
    return disallowed.join(',') as NavigationDirectionList;
}
export const navWithContainerBounds = (e: NavigationEvent) => {
    const disallowed = window.disallowedDirections;
    if (disallowed != null) {
        console.log(disallowed + e.detail.dir);
        console.log(disallowed.indexOf(e.detail.dir));
        if (disallowed.indexOf(e.detail.dir) !== -1) {
            e.preventDefault();
            return;
        }
    }
    if (e.target instanceof Element) {

        window.disallowedDirections = e.target.getAttribute('data-disallowed-directions');
    }
}

export const onBackButtonEvent = (assistant: Assistant) => {
    const callback = (e: PopStateEvent) => {
        console.log(e);
        window.history.pushState(null, "", window.location.href);
        sendAction(assistant, { action_id: '/Back', parameters: {} });
        window.history.go(1);
    }
    return callback
}


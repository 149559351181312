import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { DeviceThemeProvider, SSRProvider } from '@sberdevices/plasma-ui';
import { GlobalStyle } from './GlobalStyle';
import App from './App';
import '@sberdevices/spatial-navigation';

ReactDOM.render(
    <DeviceThemeProvider>
        <SSRProvider>
            <App />
            <GlobalStyle />
        </SSRProvider>
    </DeviceThemeProvider>,
    document.getElementById('root'),
);
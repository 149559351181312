
import {
    Col, Cell, CellIcon, CardBody, CardContent, TextBox,
} from "@sberdevices/plasma-ui";
import { TabItem, Tabs, } from "@sberdevices/plasma-ui"
import { detectDevice } from '@sberdevices/plasma-ui';
import React from "react";
import { GAMES } from "../../constants";
import { sendAction, setPageCommands, } from "../../../helpers/Assistant";

import { StolotoTabs } from "../../StolotoTabs"
import { AssistantContext } from "../../../helpers/AssistantContext"
import { getTicketTime, formatTimeToDraw } from "../../../helpers/TextUtils";
import { GamesStyledCard, PayPopupImg, StyledCarousel, StyledCarouselItem } from "../../../style/style"
import TimerComponent from "../../../helpers/TimerComponent";
import {
    OnlineTicketsPageTextBoxTitle, OnlineTicketsTabsRow, OnlineTicketsPageTextBoxSubTitle, OnlineTicketsPageTextBoxSubTitleS,
    OnlineTicketsPageTextBoxTitleL, OnlineTicketsPageStyledCarouselItem, OnlineTicketsPageTabItem,
    OnlineTicketsPageTabItemFinGame, CheckTicketTabsContainer, OnlineTicketPageContainerText, OnlineTicketsTextBoxBigTitle
} from './styles/online-tickets-styles'
class OnlineTicketsVerticalCarousel extends React.Component {
    static contextType = AssistantContext;

    constructor(props) {
        super(props);
        this.state = {
            verticalCarouselIndex: 0,
            
        }
        
    }
    
    getGameSettings(gameId) {
        return GAMES.find(function (game, index) {
            if (game.id === gameId) {
                return game;
            }
        })
    }

    checkTicket(assistant, ticket) {
        if(!this.timer ){
            this.props.wasClickedGameCheck()
            
                sendAction(assistant, {
                    action_id: "CHECK_TICKET",
                    parameters: {
                        draw: ticket.edition,
                        numbers: ticket.uniqueId,
                        game_id: ticket.gameName,
                    }
                }
            )
            
        }     
        this.timer = setTimeout(()=>{this.timer=undefined}, 1500)

    }

    getPaddingLeftCarouselItem() {
        if (detectDevice() == 'mobile') {
            return '0px'
        }
        if (detectDevice() == 'sberPortal') {
            return '0px'
        }
        if (detectDevice() == 'sberBox') {
            return '8px'
        }
    }

    getTicketsVerticalCarousel(ticketClassArray, ticketClassName) {
        let tr = []
        ticketClassArray.sort((firstItem, secondItem) => (new Date(secondItem.dateAndTime).valueOf() - new Date(firstItem.dateAndTime).valueOf()))
      
        ticketClassArray.map((ticket, key) => {
            tr.push(
                <AssistantContext.Consumer>
                    {assistant => (
                        <StyledCarouselItem
                            id={key}
                            onFocus={(e) => { this.vertical(key) }}
                            key={key}
                            tabIndex={-1}
                            scrollSnapAlign="center"
                            isZeroPadding={true}
                            style={{ background: '#000000', paddingLeft: this.getPaddingLeftCarouselItem(), paddingRight: '8px', }}
                        >
                            <GamesStyledCard
                                roundness={'12'}
                                data-disallowed-directions={key+1==ticketClassArray.length && ['down']}
                                tabIndex={this.props.messagePopupWasOpened || !this.props.canJumpToThis || detectDevice()!=='sberBox' ? -1 : 0}
                                onKeyUp={(e) => {
                                    if(e.key === "Enter" && !this.props.checkTicketPressDisabled ){
                                        this.checkTicket(assistant, ticket)
                                    }
                                   
                                }}
                                onClick={(e) => { 
                                    if(detectDevice() != "sberBox" && !this.props.checkTicketPressDisabled) {
                                        this.checkTicket(assistant, ticket)
                                    }
                                     }}
                            >
                                <CardBody style={{ padding: '0px' }}>

                                    <CardContent style={{ padding: '0px' }} compact>
                                        <Cell
                                            alignLeft={true}
                                            alignRight={true}
                                            contentLeft={
                                                <CellIcon style={{ display: 'contents' }}>
                                                    <PayPopupImg src={process.env.PUBLIC_URL + "/mini/" + this.getGameSettings(ticket.gameName).gameLogo} />
                                                </CellIcon>

                                            }
                                            content={
                                                <TextBox style={{ paddingTop: '0px', }}>
                                                    <OnlineTicketsPageTextBoxTitle>Тираж №{ticket.edition}</OnlineTicketsPageTextBoxTitle>
                                                    <OnlineTicketsPageTextBoxSubTitle>Билет №{ticket.displayNumber}</OnlineTicketsPageTextBoxSubTitle>
                                                    {new Date(ticket.dateAndTime) > new Date() ?
                                                        <TimerComponent status={ticket.status} myTicketsScreen={true} timerEndReaction={() => this.forceUpdate()} targetTime={ticket.dateAndTime} /> :
                                                        ticket.status === 'COMPLETED'? 
                                                        <OnlineTicketsPageTextBoxSubTitleS  myTicketsScreen={true}> {formatTimeToDraw(-1, ticket.dateAndTime)}</OnlineTicketsPageTextBoxSubTitleS>:
                                                        <OnlineTicketsPageTextBoxSubTitleS  myTicketsScreen={true}> {getTicketTime(ticket.dateAndTime)}</OnlineTicketsPageTextBoxSubTitleS>
                                                    }
                                                </TextBox>
                                            }
                                        />
                                    </CardContent>

                                </CardBody>
                            </GamesStyledCard>
                        </StyledCarouselItem>
                    )}
                </AssistantContext.Consumer>
            )
        })
        return tr
    }

    vertical(displayNumber) {
        if(detectDevice()!=='mobile'){
            this.setState({ verticalCarouselIndex: displayNumber })
        }
        
    }


    getStylesCarousel() {
        if (detectDevice() == 'sberBox') {
            return { height: '24em' }
        }
        if (detectDevice() == 'sberPortal') {
            return { height: '12em',}
        }

    }

    onIndexChangeControl(index){
        if(detectDevice()!=='sberBox'){
            this.props.editHorisontalIndex(this.props.currentHorisontalIndex)
            this.setState({ verticalCarouselIndex: index })
        }
       
    }
    render() {
        console.log('i am rendered')
        return (
            < >
                <OnlineTicketsTextBoxBigTitle>
                    {
                        this.props.ticketsArray[0].additionGameName != 'sportloto' ?
                            (this.getGameSettings(this.props.ticketsArray[0].gameName).rusName) :
                            ('Спортлото')
                    }
                </OnlineTicketsTextBoxBigTitle>
                <StyledCarousel
                className="asdsa"
                    onIndexChange={(index) => this.onIndexChangeControl(index) }
                    detectActive={true}
                    scrollAlign="center"
                    scrollSnapType='mandatory'
                    scrollSnapAlign="center"
                    axis={'y'}
                    index={this.state.verticalCarouselIndex}
                    paddingEnd={detectDevice() != 'mobile' && '45%'}
                   // paddingStart={detectDevice() == 'sberPortal' && '20px'}
                    detectThreshold={detectDevice()=='sberBox'? 0.8 : 0.4}
                    style={this.getStylesCarousel()}
                >

                    {this.getTicketsVerticalCarousel(this.props.ticketsArray, this.props.ticketClassName,)}
                </StyledCarousel>

            </>
        );
    }
}


export default OnlineTicketsVerticalCarousel;
import styled from 'styled-components';
import {
    Card, CardContent, TextBoxBigTitle, TextBoxSubTitle, 
    CardParagraph1, Headline3, CarouselGridWrapper, 
} from "@sberdevices/plasma-ui";
import React from 'react';
import { StyledCarouselItem } from '../../style/style';

export const StyledCarouselWrapper = styled(CarouselGridWrapper)`
  margin-top: -8px;
  margin-bottom: -8px;
  @media (min-width: 1281px) and (max-width: 1961px) {
    width: 100%;
    // display: flex;
    // justify-content: space-around;
    
  }
  @media (min-width: 961px) and (max-width: 1281px) { 
    width: 100%;
  }
  @media (min-width: 140px) and (max-width: 960px) {
    width: 100%;
  }
`;
export const StyledSectionTitle = styled(Headline3)`
  padding-bottom: 20px;
  padding-top: 20px;
  transition: transform 0.15s linear;
  transform: ${props => props.active ||  'translateX(2em)' }; 
  @media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 56px;
    transform: ${props => props.active ||  'translateX(2.1em)' }; 
  }
  @media (min-width: 961px) and (max-width: 1281px) { 
      font-size: 40px;
      transform: ${props => props.active ||  'translateX(2.25em)' };
  }
  @media (min-width: 140px) and (max-width: 960px) {
      font-size: 20px;
      transform: ${props => props.active ||  'translateX(1.35em)' }; 
  }
`;

export const GameCategoryStyledCarouselItem = styled(StyledCarouselItem)`
    @media (min-width: 1281px) and (max-width: 1961px) {
        width: 534px;
        height:492px;
        padding-right: 24px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        width: 330px;
        height:362px;
        padding-right: 18px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        width: 148px;
        height:180px;
        padding-right: 9px;
    }  
`;

export const GameCategoryCard = React.memo(
  styled(Card)`
    @media (min-width: 1281px) and (max-width: 1961px) {
        //border-radius: 32px;
        width: 534px;
        height: 492px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        border-radius: 16px;
        width: 330px;
        height: 362px;

    }
    @media (min-width: 140px) and (max-width: 960px) {
        border-radius: 8px;
    
    }  
`);

export const GameCategoryTextBoxBigTitle= styled(TextBoxBigTitle)`
    //margin-bottom: 0px;
    height: 27px;
    font-weight: 600;
    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size: 56px;
        padding-bottom: 5px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size: 32px;
        margin: 0px;
        //padding-bottom: 3px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        font-size: 14px;
       // padding-bottom: 3px;
        height: 9px;
    }  
`;

export const GameCategoryCardParagraph1Colored= styled(CardParagraph1)`
    
    margin-bottom: 0px;
    color: #EE6820;  
    margin-top: -15px;

    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size: 28px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size: 20px;
        margin-top: -20px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        font-size: 10px;
        margin-top: -10px;
    }  
`;

export const GameCategoryCardParagraph1= styled(CardParagraph1)`
    font-weight: 600;
    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size: 32px;
        padding-bottom: 5px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size: 20px;
        //padding-bottom: 3px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        font-size: 10px;
        //padding-bottom: 3px;
    }  
`;

export const GameCategoryTextBoxSubTitle= styled(TextBoxSubTitle)`

@media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 28px;
}
@media (min-width: 961px) and (max-width: 1281px) {
    font-size: 20px;
}
@media (min-width: 140px) and (max-width: 960px) {
    font-size: 10px;
}  
`;

export const GameCategoryCardContent= styled(CardContent)`
    margin-top:5em;
    @media (min-width: 1281px) and (max-width: 1961px) {
        margin-top:8em;
    }

`;
import React from "react";
import {
    Row, Tabs, TextBox,Col, detectDevice
} from "@sberdevices/plasma-ui";
import KenoFirstGameTabItemComponent from './KenoFirstGameTabItemComponent'
import {KenoFirstGameTextBoxSubTitle,KenoFirstGameTabItem, KenoFirstGameCol, KenoFirstGameRow} from './styles/keno-styles'
class KenoFirstGame extends React.Component {
    constructor(props) {
        super(props);
    }

    getLineStylesForMobile(){
        return detectDevice()=='mobile' ? { width: '100%',  borderBottom: '1px solid rgba(255,255,255,0.2)',marginLeft: '-3px', } : {  }
    }

    setFirstGameChoiseKeno=(key,ticketNumber, keyIndex )=>{
        this.props.setFirstGameChoiseKeno(key, ticketNumber, keyIndex)
    }
    render(){
        console.log(this.props.waitResult)
        return (
            <KenoFirstGameCol id={this.props.keyIndex} >
                <Row id={this.props.keyIndex}>
                    <TextBox >
                        <KenoFirstGameTextBoxSubTitle>Каких чисел выпадет больше?</KenoFirstGameTextBoxSubTitle>
                    </TextBox>
                </Row>
                <KenoFirstGameRow id={this.props.keyIndex}>
                    <Tabs index={this.props.keyIndex} id={this.props.keyIndex} style={{ backgroundColor: 'rgba(255, 255, 255, 0.12)', display: 'contents', }} key={this.props.keyIndex}>
                    {this.props.rules['firstGame'].map((gameItem, key)=>(  
                        
                        // <KenoFirstGameTabItem 
                        // //setFirstGameChoiseKeno={this.props.setFirstGameChoiseKeno} 
                        //     //isActive={gameItem.key === this.props.ticketInfo.firstGame}
                        //     activeTab={gameItem.key === this.props.ticketInfo.firstGame}
                        //     id={this.props.keyIndex}
                            
                        //     key={key}
                        //     tabIndex={1} > 
                        //     {gameItem.rusName}
                        // </KenoFirstGameTabItem>
                             <KenoFirstGameTabItemComponent
                             waitResult={this.props.waitResult}
                             messagePopupWasOpened={this.props.messagePopupWasOpened}
                             setFirstGameChoiseKeno={this.setFirstGameChoiseKeno}
                             activeTab={gameItem.key === this.props.ticketInfo.firstGame}
                             keyIndex={this.props.keyIndex}
                             rusName={gameItem.rusName}
                             gameKey={gameItem.key}
                             key={key}
                             ticketNumber={this.props.ticketNumber}
                         />
                        )
                       
                    )}
                    </Tabs>
                </KenoFirstGameRow>
                <Row id='target3' style={this.getLineStylesForMobile()}></Row>
            </KenoFirstGameCol>
        )
    }
    
}
export default KenoFirstGame;
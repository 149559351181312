import React from "react";
import styled from 'styled-components';
import {
    Button, detectDevice, Card, TabItem, Tabs, CarouselItem
} from "@sberdevices/plasma-ui";
import { isRefFromPopup } from "../../../../helpers/RefHelpers";
// import { ButtonSpec } from "../styles/lotto-ticket-styles";

const ButtonSpec = React.memo(styled(Button)`
    color: ${({ color }) => color};
    display:  ${({ display }) => display};
    user-select: none;

`);


class Square extends React.Component {
    constructor(props) {
        super(props);
        this.nameInput = null;
    }

    setNumberInSquare() {
        if (!this.props?.ticketWaiting && this.props.isSquareActive) {
            this.props.setNumber(this.props.currentNumber, this.props.ticketNumber, this.props.colNum, this.props.rowNum, this.props.ticketType)

        }
    }

    makeRefFocus(){
        if( 
            detectDevice()=='sberBox' && 
            this.props.currentNumber=='1' && 
            this.props.ticketType=='mainTicket' && 
            !['keno2', 'top3'].includes(this.props.gameName) &&
            this.props.squareColor!='success' &&
            !this.props.waitResult
        ){
            this.nameInput?.focus()
        }
    }

    // componentWillReceiveProps(nextProps){
    //     if(!nextProps.messagePopupWasOpened && isRefFromPopup()){
    //         this.makeRefFocus()
    //     }
    // }

    componentDidMount(){
        this.makeRefFocus() 
    }

    render() {

        const buttonStyles = {
            '6x45': {
                'sberBox': { width: '88px', height: '88px', fontSize: '32px', padding: '4px', borderRadius: '26px', fontWeight: '400' },
                'sberPortal': { width: '64px', height: '64px', fontSize: '24px', padding: '3px', borderRadius: '18px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '3px', borderRadius: '8px', fontWeight: '400' },
            },
            '7x49': {
                'sberBox': { width: '88px', height: '88px', fontSize: '32px', padding: '4px', borderRadius: '26px', fontWeight: '400' },
                'sberPortal': { width: '64px', height: '64px', fontSize: '24px', padding: '3px', borderRadius: '18px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '3px', borderRadius: '8px', fontWeight: '400' },
            },
            '1224': {
                'sberBox': { width: '88px', height: '88px', fontSize: '32px', padding: '4px', borderRadius: '26px', fontWeight: '400' },
                'sberPortal': { width: '68px', height: '68px', fontSize: '24px', padding: '3px', borderRadius: '18px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '4px', borderRadius: '8px', fontWeight: '400' },
            },
            'zabava': {
                'sberBox': { width: '88px', height: '88px', fontSize: '32px', padding: '4px', borderRadius: '26px', fontWeight: '400' },
                'sberPortal': { width: '68px', height: '68px', fontSize: '24px', padding: '3px', borderRadius: '18px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '4px', borderRadius: '8px', fontWeight: '400' },
            },
            'rapido': {
                'sberBox': { width: '88px', height: '88px', fontSize: '32px', padding: '5px', borderRadius: '26px', fontWeight: '400' },
                'sberPortal': { width: '68px', height: '68px', fontSize: '24px', padding: '3px', borderRadius: '16px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '3px', borderRadius: '8px', fontWeight: '400' },
            },
            'rapido2': {
                'sberBox': { width: '88px', height: '88px', fontSize: '32px', padding: '5px', borderRadius: '26px', fontWeight: '400' },
                'sberPortal': { width: '68px', height: '68px', fontSize: '24px', padding: '3px', borderRadius: '16px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '3px', borderRadius: '8px', fontWeight: '400' },
            },
            'rapido-drive': {
                'sberBox': { width: '88px', height: '88px', fontSize: '32px', padding: '5px', borderRadius: '26px', fontWeight: '400' },
                'sberPortal': { width: '68px', height: '68px', fontSize: '24px', padding: '3px', borderRadius: '16px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '3px', borderRadius: '8px', fontWeight: '400' },
            },
            'rapido-ultra': {
                'sberBox': { width: '88px', height: '88px', fontSize: '32px', padding: '5px', borderRadius: '26px', fontWeight: '400' },
                'sberPortal': { width: '68px', height: '68px', fontSize: '24px', padding: '3px', borderRadius: '16px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '3px', borderRadius: '8px', fontWeight: '400' },
            },
            '5x36plus': {
                'sberBox': { width: '88px', height: '88px', fontSize: '32px', padding: '5px', borderRadius: '26px', fontWeight: '400' },
                'sberPortal': { width: '64px', height: '48px', fontSize: '24px', padding: '3px', borderRadius: '16px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '2px', borderRadius: '8px', fontWeight: '400' },
            },
            '5x2': {
                'sberBox': { width: '80px', height: '70px', fontSize: '32px', padding: '4px', borderRadius: '20px', fontWeight: '400' },
                'sberPortal': { width: '60px', height: '42px', fontSize: '24px', padding: '3px', borderRadius: '12px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '2px', borderRadius: '8px', fontWeight: '400' },
            },
            '4x20': {
                'sberBox': { width: '88px', height: '88px', fontSize: '32px', padding: '5px', fontWeight: '400', borderRadius: '26px', fontWeight: '400' },
                'sberPortal': { width: '60px', height: '60px', fontSize: '24px', padding: '3px', borderRadius: '16px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '2px', borderRadius: '8px', fontWeight: '400' },
            },
            'oxota': {
                'sberBox': { width: '88px', height: '88px', fontSize: '32px', padding: '5px', fontWeight: '400', borderRadius: '26px', fontWeight: '400' },
                'sberPortal': { width: '60px', height: '60px', fontSize: '24px', padding: '3px', borderRadius: '16px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '2px', borderRadius: '8px', fontWeight: '400' },
            },
            'duel': {
                'sberBox': { width: '88px', height: '64px', fontSize: '32px', padding: '5px', fontWeight: '400' },
                'sberPortal': { width: '60px', height: '48px', fontSize: '24px', padding: '3px', borderRadius: '16px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '2px', borderRadius: '8px', fontWeight: '400' },
            },
            'dvazhdydva': {
                'sberBox': { width: '88px', height: '64px', fontSize: '32px', padding: '5px', fontWeight: '400' },
                'sberPortal': { width: '60px', height: '48px', fontSize: '24px', padding: '3px', borderRadius: '16px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '2px', borderRadius: '8px', fontWeight: '400' },
            },
            'top3': {
                'sberBox': { width: '88px', height: '56px', fontSize: '28px', padding: '5px', borderRadius: '22px', fontWeight: '400' },
                'sberPortal': { width: '68px', height: '44px', fontSize: '24px', padding: '2px', borderRadius: '16px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '3px', borderRadius: '8px', fontWeight: '400' },
            },
            'keno2': {
                'sberBox': { width: '80px', height: '40px', fontSize: '29px', padding: '4px', borderRadius: '12px', fontWeight: '400' },
                'sberPortal': { width: '60px', height: '48px', fontSize: '22px', padding: '2px', borderRadius: '16px', fontWeight: '400' },
                'mobile': { width: '30px', height: '30px', fontSize: '14px', padding: '2px', borderRadius: '8px', fontWeight: '400' },
            },
        }
        return (

            <td>

                <Card
                
                //onFocus={()=>{this.nameInput?.focus()}}
                id={`focus_${this.props.currentNumber}_${this.props.ticketType}_keyTicket_${this.props.ticketKey}`}
                    onKeyUp={(e) =>
                        e.key === "Enter" &&
                        this.setNumberInSquare()
                    }
                    onClick={(e) => {
                        detectDevice() !== "sberBox" &&
                            this.setNumberInSquare()
                    }
                    }
                    //ref={(card) => { if (!this.nameInput ) { this.nameInput = card } }}
                    tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                    roundness={'12'}
                    className="square"
                    style={
                        {
                            display: 'flex',
                            margin: buttonStyles[this.props.gameName][detectDevice()].padding,
                            marginLeft: this.props.isFarLeftSquare ? '0px' :
                                buttonStyles[this.props.gameName][detectDevice()].padding,
                        }
                    }
                >
                    
                    <ButtonSpec
                        
                        onKeyUp={(e) =>{
                            e.preventDefault()
                            return false
                        }
                            
                        }
                        onClick={(e) => {
                            e.preventDefault()
                            return false
                        }
                        }
                        tabIndex={-1}
                        scaleOnHover={false}
                        scaleOnPress={false}

                        key={`button_${this.props.currentNumber}_${this.props.ticketType}`}
                        outlined={false}
                        style={buttonStyles[this.props.gameName][detectDevice()]}
                        square={true}
                        view={this.props.squareColor}
                        color={this.props.color}
                        display={this.props.isDisplayNone}
                        size={'s'}
                    >
                        {this.props.currentNumber}
                    </ButtonSpec>
                </Card>

            </td>
        )
    }

}

const SquareMemo = React.memo(Square);

export default SquareMemo;
import { Col } from "@sberdevices/plasma-core";
import {
  CarouselItem,
  Container, Row, detectDevice
} from "@sberdevices/plasma-ui";
import React from "react";
import { Category } from "../helpers/StolotoTypes";
import { StyledCarousel, GamesStyledContainer } from "../style/style";
import GamesCategory from './GamesCategory'
import { StolotoTabs } from "./StolotoTabs";

class MainMenuPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselIndex: 0,
    }
  }
  changeIndex(id) {
    // setTimeout(() => {
      this.setState({ carouselIndex: id })
    // }, "130")
  }
  getCarouselItems() {
    let tr = []
    this.props.games.map((category, i) => {
      tr.push(
        <CarouselItem  style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap',flexDirection: 'column', }} scrollSnapAlign="center" key={i} id={i} onFocus={(e) => { this.changeIndex(i) }}>
          <GamesCategory gamesCategoryId={i} messagePopupWasOpened={this.props.messagePopupWasOpened} messageShow={this.props.messageShow} category_name={category.category_name} key={tr.length} games={category.games} prefix={category.prefix} />
        </CarouselItem>
      )
    }
    )
    return tr
  }
  
  render() {
   const paddingsStart = {'sberBox': '10%', 'sberPortal': '10%', }
   const paddingsEnd = {'sberBox': '10%', 'sberPortal': '10%', 'mobile': '100px'}
    return (

     
      <>
        <StolotoTabs messagePopupWasOpened={this.props.messagePopupWasOpened} activeTab='lotteries' messageShow={this.props.messageShow} />
      <GamesStyledContainer>
        <StyledCarousel
          
          detectThreshold={0.5}
          scrollSnapType="mandatory"
          detectActive={detectDevice()=='mobile' ? false: true}
          onIndexChange={(index) => this.setState({carouselIndex: index})}
          paddingEnd={paddingsEnd[detectDevice()]}
          paddingStart={paddingsStart[detectDevice()]}
          index={this.state.carouselIndex}
          axis="y"
          style={ detectDevice() === 'mobile' ? {paddingBottom: '5em'}: null}
        >
          {this.getCarouselItems()}
        </StyledCarousel>
        </GamesStyledContainer>
        <div id='footer_shadow' ></div>
        </>
       
    )
  }
}
export default MainMenuPage;
import React from "react";
import { Button, Row, TextBox, detectDevice, Card, Col } from "@sberdevices/plasma-ui";
import { MessageContainerDraw, MessageDrawContent, } from '../../../../style/style'
import { IconChevronRight, IconChevronLeft, IconClose, IconCross } from '@sberdevices/plasma-icons';
import {
    KenoThirdGameStyledCarouselItem, KenoThirdGameStyledCarousel, KenoThirdGameTabs,
    KenoThirdGameTabItem, KenoThirdGameTextBoxSubTitle, KenoThirdGameOutlineGreenwrapper, KenoThirdGameTextBoxSubTitleMobile,
    KenoThirdGameTabItemMobileListCols, KenoThirdGameTextBoxRule
} from './styles/keno-styles'
import KenoThirdGameCol from './KenoThirdGameCol'
import { MobileTabsTextBoxTitleMini } from '../../../styles/tabs-mobile-styles'

class KenoThirdGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openTabsPopup: false,
        }
    }

    checkIfSelected(colNum) {

        return this.props.ticketInfo['threeGame'].has(colNum)
    }

    getThreeGameCols(activeCols) {
        return (

            <KenoThirdGameStyledCarousel axis='x' >
                {Array.from({ length: this.props.rules['threeGame']['firstTicket']['cols'] }, (_, i) => i).map((col, key) => (
                    <KenoThirdGameStyledCarouselItem key={key} scrollSnapAlign="center"  >
                        <KenoThirdGameOutlineGreenwrapper 

                            roundness={'8'} 
                            onClick={() => { 
                                detectDevice() !== "sberBox" && 
                                this.props.setThreeGameChoise(this.props.ticketInfo.key, col + 1) 
                                }
                                } 
                            tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                            onKeyUp={(e) =>
                                e.key === "Enter" &&
                                this.props.setThreeGameChoise(this.props.ticketInfo.key, col + 1)
                              }
                            >
                            <KenoThirdGameTabs waitResult={this.props.waitResult} tabIndex={-1} outlined={false} activeCol={this.props.ticketInfo['threeGame'].has(col + 1)} size='s' view='secondary'  >
                                {Array.from({ length: this.props.rules['threeGame']['firstTicket']['rows'] }, (_, i) => i + 1).map((row, key) => (
                                    row == 1 ? 
                                    <KenoThirdGameCol
                                    waitResult={this.props.waitResult}
                                    outlined={false}
                                    isFirstTabInCol={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(col + 1)}
                                    isActive={false}
                                    activeCol={this.props.ticketInfo['threeGame'].has(col + 1)}
                                    colNumber={col + 1}
                                    /> :
                                    <KenoThirdGameCol
                                    
                                    outlined={false}
                                   
                                    isActive={false}
                                    activeCol={false}
                                    colNumber={((row - 1) * 10 + (col + 1))}
                                    /> 
                                ))}
                            </KenoThirdGameTabs>
                        </KenoThirdGameOutlineGreenwrapper>

                    </KenoThirdGameStyledCarouselItem>
                ))}
            </KenoThirdGameStyledCarousel>

        )
    }

    getHeaderPayText() {
        return 'К ОПЛАТЕ: ' + this.props.payMoney 
    }

    closeGamePopup() {
        !this.props.waitResult && this.props.setThreeGameEmpty(this.props.ticketInfo.key)
        this.setState({ openTabsPopup: false })
    }

    getChunkedTicketsInfo() {
        let chunkSize = 3;
        let newArray = []
        let ticketsInfo = Array.from(this.props.ticketInfo['threeGame'])
        for (let i = 0; i < ticketsInfo.length; i += chunkSize) {
            const chunk = ticketsInfo.slice(i, i + chunkSize);
            if(chunk.length == 1){
                chunk.push(0)
                chunk.push(0)
            }
            if(chunk.length == 2){
                chunk.push(0)
            }
            newArray.push(chunk)
        }
        return newArray
    }

    getVisibleStatusText(number){
        return number!=0 ? {visibility: 'visible'} : {visibility: 'hidden'}

    }

    drawMobileSelectedInfoCols() {
        let chunkedColsInfo = this.getChunkedTicketsInfo()
        let gamesTabs = []
        chunkedColsInfo.map((ticketCols, key) =>
        (
            gamesTabs.push(            
            <KenoThirdGameTabs view='secondary' style={{ height: '36px', background: '#1F1F1F', display: 'flex', width: '100%', justifyContent: 'center' }}>
                {ticketCols.map((col, key) =>(
                    <KenoThirdGameTabItemMobileListCols waitResult={this.props.waitResult} style={this.getVisibleStatusText(col)}> {col} столбец </KenoThirdGameTabItemMobileListCols>
                ))}
            </KenoThirdGameTabs>)
        )
    )
       return gamesTabs
    }

    render() {
        return (
            <>
                {
                    detectDevice() == 'mobile' ?
                        <MessageContainerDraw style={this.state.openTabsPopup ? { display: 'grid', justifyContent: 'center', justifyItems: 'center', alignContent: 'center' } : { display: 'none' }}>
                            <MessageDrawContent>
                                <Col>
                                    <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px', paddingTop: '3px' }}>
                                        <MobileTabsTextBoxTitleMini style={(this.props.showPayText) ? {visibility: 'visible'}:{visibility: 'hidden'}}>{this.getHeaderPayText()}</MobileTabsTextBoxTitleMini>
                                        <span onClick={() => { this.closeGamePopup() }} style={{ fontSize: '1.5em', cursor: 'pointer', }}><IconCross></IconCross></span>
                                    </Row>
                                    <Row>
                                        <KenoThirdGameTextBoxSubTitleMobile>В каком столбце будет больше выпавших чисел?</KenoThirdGameTextBoxSubTitleMobile>
                                    </Row>
                                    <Row>
                                        {this.getThreeGameCols()}
                                    </Row>
                                    <Row style={{ paddingTop: '20px', paddingBottom: '16px' }}><Button onClick={() => { this.setState({ openTabsPopup: false }) }} style={{ fontWeight: '400' }} size={'s'} stretch={true} text={'Применить'} /></Row>
                                </Col>
                            </MessageDrawContent>
                        </MessageContainerDraw> : null
                }
                {detectDevice()=='mobile' && <Row style={{ width: '95%', paddingTop: '16px', borderBottom: '1px solid rgba(255,255,255,0.2)',}}></Row>}
                <Row >
                    <KenoThirdGameTextBoxRule >
                        <KenoThirdGameTextBoxSubTitle>В каком столбце будет больше выпавших чисел?</KenoThirdGameTextBoxSubTitle>
                    </KenoThirdGameTextBoxRule>
                </Row>
                <Col>
                    {detectDevice() == 'mobile' && <Row style={{ paddingBottom: '10px', width: '281px' }}>{this.drawMobileSelectedInfoCols()}</Row>}
                    <Row style={detectDevice()=='mobile'? {paddingBottom: '28px'}: {}}>
                        {detectDevice() == 'mobile' ?
                            <Button
                                size='s'
                                style={detectDevice() == 'mobile' ? { width: ' 100%', } : {}}
                                onClick={() => this.setState({ openTabsPopup: true })}>
                                Выбрать столбец
                            </Button> : this.getThreeGameCols()}
                    </Row>
                </Col>


            </>
        )
    }

}

export default KenoThirdGame;
import styled from "styled-components";

import { Row, CarouselCol } from "@sberdevices/plasma-ui";

import {StyledCarouselItem} from '../../../style/style'

export const ControlTicketFormRow = styled(Row)`
    //height: 100%;
    @media (min-width: 1281px) and (max-width: 1961px) {
         margin-top: ${props => props.makeTopMargin };
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        // margin-top: -1.5em;
        margin-top: ${props => props.makeTopMargin };
    }
    @media (min-width: 140px) and (max-width: 960px) {   
        margin-top: ${props => props.makeTopMargin };
    }  
`;

export const ControlTicketFormStyledCarouselItem = styled(CarouselCol)`
    //height: 30em; 
    min-width: 19.5rem; 
    //display: contents;
    //margin-right: 9px;
    
`;
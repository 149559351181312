import { PayPopupImg, ActivityButton, MessageDrawContent, MessageContainerDraw } from "../../style/style"
import {
    CardContentButtons, GetPayPopupCross, CardBlackBackground,
    ButtonContainer, PayPopupTextBoxBigTitle, BuyTicketPopup,
    QtyControlInPayPopup, BuyButtonInPopup, QtyTextBoxTitlePopup,
    BuyPopupRow, CardContentPayPopup,
} from './styles/common-elements-styles'
import React from "react";
import { CardContent, Cell, TextBox, TextBoxTitle, TextBoxSubTitle, CellIcon, Row, detectDevice, Spinner } from '@sberdevices/plasma-ui';
import { AssistantContext } from "../../helpers/AssistantContext";
import { formatMoney } from "../../helpers/TextUtils";
import { GAMES } from "../constants";
import { formatTimeToDraw } from "../../helpers/TextUtils"
import { IconPlus, IconMinus } from '@sberdevices/plasma-icons';
import { sendText, sendAction, setPageCommands } from '../../helpers/Assistant'
import TimerComponent from "../../helpers/TimerComponent";
import { EditionActionsPopupCross } from "./styles/common-elements-styles";
import { IconCross } from "@sberdevices/plasma-icons";
import { RULES_TOP3, SCREEN_KENO, isBingo } from "../constants";
class ButtonsActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            drawQty: 1,
            activeDrawInfo: this.props.activeDrawInfo,
            buyLoading: false,
            saleActive: new Date(this.props.activeDrawInfo.drawDate) > new Date(),
            checkTime: null
        }
        this.nameInput = null
    }
    static contextType = AssistantContext;

    componentWillReceiveProps(nextProps) {
        if (nextProps.ticketsQty === 0 && this.props.ticketsQty !== 0) {
            sendAction(this.context,  { action_id: "MINIMAL_BET_CANCEL", parameters: {} })
        }
        if (nextProps.ticketsQty !== 0 && this.props.ticketsQty === 0) {
            sendAction(this.context,  { action_id: "MINIMAL_BET", parameters: {} })
        }
        this.setState({
            saleActive: new Date(nextProps.activeDrawInfo.drawDate) > new Date(),
            activeDrawInfo: nextProps.activeDrawInfo
        })
        if (!nextProps.messagePopupWasOpened && this.state.isOpen) {
            this.closePayPopup(true)
        }
        if (nextProps.payPopupWasOpened && !this.state.isOpen) {
            this.openPayPopup()
        }
    }

    componentDidMount() {
        const checkTime = setInterval(() => {
            this.setState({
                saleActive: new Date(this.state.activeDrawInfo.drawDate) > new Date(),
            })
        }, 1000)

        this.setState({ checkTime })
    }

    componentWillUnmount() {
        clearInterval(this.state.checkTime)
    }

    getPaySum() {
        if (typeof this.props.paySum == 'undefined') {
            return formatMoney(this.props.ticketsQty * this.props.betCost)
        } else {
            return formatMoney(this.props.paySum)
        }
    }

    getPaySumNum() {
        if (typeof this.props.paySum == 'undefined') {
            return this.props.ticketsQty * this.props.betCost
        } else {
            return this.props.paySum
        }
    }

    getPayText() {
        let currentLength = this.props.ticketsQty
        let ticketText = ''
        if (currentLength > 1 && currentLength < 5) {
            ticketText = 'а'
        }
        if (currentLength >= 5) {
            ticketText = 'ов'
        }

        const buyForText = currentLength == 0 ? (['4x20', 'oxota', 'rapido-drive', 'rapido-ultra', 'rapido', 'rapido2', 'keno2', '1224', "zabava", "dvazhdydva", "5x2", 'duel', 'top3', '5x36plus', '6x45', '7x49'].includes(this.props.gameInfo.name) ? 'Заполните билет' : 'Выберите билет') : 'Купить ' + currentLength + ' билет' + ticketText + ' за ' + this.getPaySum()
        const payFor = this.props.ticketsQty == 0 ? (['4x20', 'oxota', 'rapido', 'rapido-drive', 'rapido-ultra', 'rapido2', 'keno2', '1224', "zabava", "dvazhdydva", "5x2", 'duel', 'top3', '5x36plus', '6x45', '7x49'].includes(this.props.gameInfo.name) ? 'Заполните билет' : 'Выберите билет') : this.props.paySum ? 'Оплатить ' + formatMoney(this.props.paySum) : 'Оплатить ' + formatMoney(this.props.ticketsQty * this.props.betCost)

        return (this.props.tickets || ['4x20', 'oxota', 'rapido-drive', 'rapido-ultra', 'rapido', 'rapido2', 'keno2', '1224', "zabava", "dvazhdydva", "5x2", 'duel', 'top3', '5x36plus', '6x45', '7x49'].includes(this.props.gameInfo.name)) ? detectDevice() === 'mobile' ? payFor : buyForText : payFor
    }

    getGameSettings(gameId) {

        return GAMES.find(function (game, index) {
            if (game.id === gameId) {
                return game;
            }
        })
    }

    getDeclinationOfCount(currentDrawQty) {
        if (currentDrawQty == 1) {
            return ' тираж '
        }
        if (currentDrawQty >= 5 || currentDrawQty == 0) {
            return ' тиражей'
        }
        return ' тиражa'
    }

    getDrawQtyText() {
        const currentDrawQty = this.state.drawQty
        if (currentDrawQty > 20) {
            return currentDrawQty + this.getDeclinationOfCount(currentDrawQty % 10)
        }
        return currentDrawQty + this.getDeclinationOfCount(currentDrawQty)


    }

    openPayPopup() {
        window.lastFocusedElement = document.activeElement
        this.setState({ isOpen: true, drawQty: 1}, () => { this.nameInput.focus() })
        this.props.messagePopupWasOpenedFunction(true)
        setPageCommands(this.context, {
            INVOICE_REGISTER_STATUS: this.onInvoiceRegisterStatus.bind(this),
            START_PAY: this.openPayPopup.bind(this),
            PAYMENT_STATUS: this.onPaymentStatus.bind(this)
        })
    }

    getPayButton() {
        return (<AssistantContext.Consumer>
            {
                assistant =>
                    <ActivityButton
                        tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                        isPayButton={true}
                        id='open_pay_papup_button'
                        // style={
                        //         this.props.ticketsQty>0? {visibility: 'visible'}: {visibility: 'hidden'}
                        //     } 
                        disabled={this.props.ticketsQty == 0 || !this.state.saleActive}
                        size='s'
                        stretch={true}
                        view='success'
                        onClick={() => {
                            detectDevice() !== 'sberBox' &&
                                sendAction(this.context, { action_id: "PAY_POPUP_OPEN_ACTION", parameters: {} })
                        }}
                        onKeyUp={(e) =>

                            e.key === "Enter" &&
                            sendAction(this.context, { action_id: "PAY_POPUP_OPEN_ACTION", parameters: {} })
                        }

                    >
                        {this.getPayText()}
                    </ActivityButton>
            }
        </AssistantContext.Consumer>)
    }

    editDrawQty(count) {
        let oldQty = this.state.drawQty
        let newQty = oldQty + count
        if (newQty != 0) {
            this.setState({ drawQty: newQty })
        }
    }

    getTicketsQtyText() {

        const ticketsQty = this.props.ticketsQty
        if (ticketsQty == 1) {
            return ticketsQty + ' билет '
        }
        if (ticketsQty >= 5) {
            return ticketsQty + ' билетов '
        }
        return ticketsQty + ' билета '
    }

    onPaymentStatus(action) {
        if (action.payload.status === 'pending' && this.props.messagePopupWasOpened) {
            setTimeout(() => sendAction(this.context, { action_id: "CHECK_PAYMENT", parameters: {} }), 1000);
        } else if (action.payload.status === 'ok') {
            sendText(this.context, 'Мои билеты')
        }
    }

    onInvoiceRegisterStatus(action) {
        if (action.payload.status === 'pending') {
            setTimeout(() => sendAction(this.context, { action_id: "CHECK_INVOICE_STATUS", parameters: {} }), 1000);
        } else if (action.payload.status === 'ok') {
            this.setState({ buyLoading: false });
            if (detectDevice() === 'mobile') {
                window.open(action.payload.params.url)
            } else {
                sendAction(this.context, { action_id: "MAKE_PAYMENT", parameters: { qr: true, url: action.payload.params.url } })
            }
        }
    }

    buyTicket() {
        console.log('PRESSED PAY')
        this.setState({ buyLoading: true });
        sendAction(this.context, { 
            action_id: "FORM_INVOICE", 
            parameters: {
                bet: this.getBetInfo(),
                drawQty: this.state.drawQty,
                cost: this.getPaySumNum()
            }
         })
        this.closePayPopup(true);
    }

    getBetInfo() {
        if (isBingo(this.props.gameInfo.name)) {
            const res = {};
            res[this.props.gameInfo.name] = {
                gameMode: 'normal',
                drawId: this.state.activeDrawInfo.drawNumber,
                tickets: this.props.tickets.map(t => ({ barCode: t.barCode }))
            }
            return res
        }
        if (['6x45', '7x49', '1224', "zabava"].includes(this.props.gameInfo.name)) {
            const res = {};
            res[this.props.gameInfo.name] = {
                gameType: 'manual',
                drawId: this.state.activeDrawInfo.drawNumber,
                drawingsCount: this.state.drawQty,
                coupons: Array.from(this.props.tickets).filter(t => t.ticketWasMaximum).map(t => ({
                    fields: 
                        [{ id: 1, combination: Array.from(t.selectedNumbersFirstTicket) }]
                    
                }))
            }
            return res
        }
        if (this.props.gameInfo.name === 'top3') {
            const combination = [null, null, null];
            const gameSetting = this.props.tickets[0].gameSetting;
            [...this.props.tickets[0].selectedNumbersFirstTicket].forEach(
                ([key, val]) => { combination[key] = val.value; }
            )
            const numbersRepeat = combination.length !== new Set(combination).length;
            return {
                'top3': {
                    gameType: 'manual',
                    drawingsCount: this.state.drawQty,
                    drawId: this.state.activeDrawInfo.drawNumber,
                    multiplier: 1,
                    coupons: [
                        {
                            drawingsCount: this.state.drawQty,
                            fields: [
                                {
                                    id: 1,
                                    combination,
                                    mode: gameSetting === 'combo' ? (numbersRepeat ? 10 : 9) : RULES_TOP3[gameSetting].modeId
                                }
                            ]
                        }
                    ]
                }
            }
        }

        if (['rapido', 'rapido2', 'rapido-drive', 'rapido-ultra'].includes(this.props.gameInfo.name)) {
            const res = {};
            res[this.props.gameInfo.name] = {
                gameType: 'manual',
                drawingsCount: this.state.drawQty,
                multiplier: 1,
                drawId: this.state.activeDrawInfo.drawNumber,
                coupons: this.props.tickets.filter(t => t.ticketWasMaximum).map(t => ({
                    fields: {
                        id: 1,
                        combination: Array.from(t.selectedNumbersFirstTicket),
                        extraCombination: Array.from(t.selectedNumbersSecondTicket)
                    }
                })
                )
            }
            return res;
        }

        if (this.props.gameInfo.name === 'keno2') {
            return {
                keno2: {
                    gameType: 'manual',
                    drawingsCount: this.state.drawQty,
                    multiplier: 1,
                    drawId: this.state.activeDrawInfo.drawNumber,
                    coupons: this.props.tickets.map(t => ({
                        parity: t.firstGame === null ? null : SCREEN_KENO.firstGame.filter(mode => mode.key === t.firstGame)[0].id,
                        combination: Array.from(t.secondGame),
                        columns: Array.from(t.threeGame)
                    })
                    )
                }
            }
        }

        if (['duel', '4x20', 'oxota', '5x36plus', '5x50', '5x2', 'dvazhdydva'].includes(this.props.gameInfo.name)) {
            const res = {};
            res[this.props.gameInfo.name] = {
                gameType: 'manual',
                drawingsCount: this.state.drawQty,
                multiplier: 1,
                drawId: this.state.activeDrawInfo.drawNumber,
                coupons: this.props.tickets.filter(t => t.ticketWasMaximum).map(t => ({
                    fields: {
                        id: 1,
                        combination: Array.from(t.selectedNumbersFirstTicket),
                        extraCombination: Array.from(t.selectedNumbersSecondTicket)
                    }
                })
                )
            }
            return res;
        }

        if (this.props.gameInfo.name === 'rocketbingo') {
            return {
                rocketbingo: {
                    gameType: 'manual',
                    drawingsCount: this.state.drawQty,
                    multiplier: 1,
                    drawId: this.state.activeDrawInfo.drawNumber,
                    coupons: this.props.tickets.map(t => ({ combination: t.numbers }))
                }
            }
        }
    }

    closePayPopup(dontNotifyBackend) {
        this.setState({ isOpen: false, buyLoading: false })
        window.payPopupWasOpened = false
        this.props.messagePopupWasOpenedFunction(false)
        this.props.payPopupClosed()
        if(!dontNotifyBackend) {
            sendText(this.context, 'Понятно')
        }
    }

    getCurDrawTime() {
        const currentDraw = this.props.activeDrawInfo;
        return currentDraw.drawDate
    }

    getPayPopup() {
        return ((this.props.ticketsQty > 0) ?
            <BuyTicketPopup>

                <CardContentPayPopup >
                    <Row>
                        <EditionActionsPopupCross
                            className="functional-popup"
                            square={true}
                            right={'13px'}
                            onClick={() => { detectDevice() !== 'sberBox' && this.closePayPopup() }}
                            onKeyUp={(e) =>
                                e.key === "Enter" &&
                                this.closePayPopup()
                            }

                        >
                            <IconCross />
                        </EditionActionsPopupCross>
                    </Row>
                    <Cell
                        content={
                            <PayPopupTextBoxBigTitle>{this.props.paySum ? formatMoney(this.props.paySum * this.state.drawQty) : formatMoney(this.props.ticketsQty * this.props.betCost * this.state.drawQty )} к оплате</PayPopupTextBoxBigTitle>
                        }
                    />
                    <Cell
                        alignLeft={true}
                        alignRight={true}
                        contentLeft={
                            <CellIcon style={{ display: 'contents' }}>
                                <PayPopupImg src={process.env.PUBLIC_URL + "/mini/" + this.getGameSettings(this.props.gameInfo.name)?.gameLogo} />
                            </CellIcon>
                        }
                        content={
                            <TextBox style={{ paddingTop: '0px', }}>
                                <TextBoxTitle><span style={{ fontWeight: 'bold' }}>{this.getTicketsQtyText()}</span>{detectDevice() == 'mobile' && <br />}«{this.getGameSettings(this.props.gameInfo.name)?.rusName}»</TextBoxTitle>
                                <TextBoxSubTitle style={{ marginTop: '0px' }}>{<TimerComponent mobileDrawPopup={detectDevice() == 'mobile'} timerEndReaction={() => { return null }} targetTime={this.getCurDrawTime()} />} </TextBoxSubTitle>
                            </TextBox>
                        }
                    />
                    <BuyPopupRow >
                        {isBingo(this.props.gameInfo.name) ? null :
                            <>
                                <QtyControlInPayPopup size={'s'} square={true} roundness={'12'} onClick={() => { this.editDrawQty(-1) }}><IconMinus size='xs'></IconMinus></QtyControlInPayPopup>
                                <QtyTextBoxTitlePopup >{this.getDrawQtyText()}</QtyTextBoxTitlePopup>
                                <QtyControlInPayPopup size={'s'} square={true} onClick={() => { this.editDrawQty(1) }}><IconPlus size='xs'></IconPlus></QtyControlInPayPopup>
                            </>
                        }
                        <BuyButtonInPopup
                            id='pay_popup_button'
                            className="functional-popup"
                            ref={(input) => { this.nameInput = input; }}
                            style={isBingo(this.props.gameInfo.name) ? { marginLeft: '0px', marginTop: '0px' } : {}}
                            secondQtyButton={true}
                            square={true}
                            onClick={() => { detectDevice() !== 'sberBox' && this.buyTicket() }}
                            onKeyUp={(e) =>
                                e.key === "Enter" &&
                                this.buyTicket()
                            }

                            view="success">
                            {this.state.buyLoading ? <Spinner color="#ffffff" /> : "Купить"}
                        </BuyButtonInPopup>
                    </BuyPopupRow>

                </CardContentPayPopup>
            </BuyTicketPopup> : null
        )
    }

    pressButton() {
        this.props.actionButton()

    }

    render() {
        const stylesPayPopup = {
            'sberBox': { paddingBottom: '0em' },
            'sberPortal': { paddingBottom: '0em' },
            'mobile': { paddingBottom: '0.25em' },
        }
        return (
            <>
                <MessageContainerDraw id='pay_popup' style={this.state.isOpen ? { display: 'grid', justifyContent: 'center', alignContent: 'center' } : { display: 'none' }}>
                    <MessageDrawContent style={stylesPayPopup[detectDevice()]}>

                        {this.getPayPopup()}
                    </MessageDrawContent>
                </MessageContainerDraw>
                <CardBlackBackground >
                    <CardContentButtons >
                        {!this.props?.waitingPage ?
                            <ButtonContainer >
                                {
                                    isBingo(this.props.gameInfo.name) ?
                                        <AssistantContext.Consumer>
                                            {
                                                assistant => <ActivityButton
                                                    generateNew={true}
                                                    tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                                                    style={{ background: '#1F1F1F' }}
                                                    size='s'
                                                    stretch={true}
                                                    onClick={() => { detectDevice() !== 'sberBox' && this.props.actionButton(assistant) }}
                                                    onKeyUp={(e) =>
                                                        e.key === "Enter" &&
                                                        this.props.actionButton(assistant)
                                                    }
                                                >
                                                    Сгенерировать новые
                                                </ActivityButton>
                                            }
                                        </AssistantContext.Consumer> :
                                        <ActivityButton
                                            disableButton={this.props.ticketsInCarousel >= 5}
                                            tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                                            size='s'

                                            stretch={true}
                                            style={{ background: '#1F1F1F', visibility: 'hidden', display: detectDevice() == 'mobile' && 'none' }}
                                            onKeyUp={(e) =>
                                                e.key === "Enter" &&
                                                this.pressButton()
                                            }
                                            onClick={(e) => {
                                                detectDevice() !== "sberBox" &&
                                                    this.pressButton()
                                            }
                                            }
                                        >
                                            Добавить билет
                                        </ActivityButton>
                                }
                                {
                                    this.getPayButton()
                                }
                            </ButtonContainer> :
                            <ButtonContainer>
                                <AssistantContext.Consumer>
                                    {
                                        assistant => <ActivityButton autoFocus={detectDevice() != 'mobile' && true} tabIndex={this.props.messagePopupWasOpened ? -1 : 0} view='success' size='s' stretch={true} onClick={() => sendText(assistant, 'играть ещё')}>Играть ещё</ActivityButton>
                                    }
                                </AssistantContext.Consumer>
                                <AssistantContext.Consumer>
                                    {
                                        assistant => <ActivityButton tabIndex={this.props.messagePopupWasOpened ? -1 : 0} size='s' stretch={true} onClick={() => sendText(assistant, 'назад')}>Назад</ActivityButton>
                                    }
                                </AssistantContext.Consumer>


                            </ButtonContainer>

                        }
                    </CardContentButtons>
                </CardBlackBackground>
            </>
        )
    }

}


export default ButtonsActions;
import { Button, Container, TextField, Row, Col, ParagraphText2 } from "@sberdevices/plasma-ui";
import axios from "axios";
import React from "react";
import { sendAction, sendText, setPageCommands } from "../helpers/Assistant";
import { AssistantContext } from "../helpers/AssistantContext";

import { detectDevice } from '@sberdevices/plasma-ui';
import { StolotoTabs } from "./StolotoTabs";
import { IconChevronRight, IconChevronLeft, IconClose, IconCross } from '@sberdevices/plasma-icons';
import { StolotoTabsImageAppName } from './styles/stoloto-tabs-styles'
import {addGeneralCommand} from '../helpers/Assistant'
import { isRefFromPopup } from "../helpers/RefHelpers";
import {AuthPageContainer, AuthPageMessageContainer,AuthPageContent,
  AuthPageParagraphText1Main, AuthPageCol, AuthPageImgQr,
  AuthPageWrongPswd, AuthPageButtonLogin, AuthPageButtonForgotPswd,
  AuthPageParagraphText1Policy, 
  AuthPagePayPopupCrossStyles, AuthPagePayPopupImg, AuthPagePayPopupParagraphText1, 
  AuthPageButtonUnderstand, AuthPagePayPopupRow, AuthPageMainContainer,AuthPageTextCol,
  AuthPageTextFieldRow, ButtonLink } from './styles/auth-page-styles'


class AuthPage extends React.Component {
  static contextType = AssistantContext;


  constructor(props) {
    super(props);
    this.state = {
      login: "",
      password: "",
      error_text: "",
      error_code: "",
      show_forget: false,
      isOpen: false,
    };
    this.nameInput = null
  }
  
  // componentWillReceiveProps(nextProps){
  //    if(!nextProps.messagePopupWasOpened && isRefFromPopup()){   
  //       this.nameInput.focus(); 
  //    }
  //  }

  componentDidMount() {
    this.nameInput?.focus();
    setPageCommands(this.context, {
      BAD_AUTH: (action) => {
        this.badAuth(action.payload);
      },
      AUTH: (action) => {
        this.auth(action.payload?.token).then(() => { })
      },
    });
  }

  badAuth(payload) {
    this.setState({
      error_code: payload.error_code,
      error_text: payload.text,
      show_forget: payload.error_code === "auth.wrong",
    });
  }

  forgetPassword() {
    this.setState({ isOpen: true })
  }

  pressUnderstand(){
    sendText(this.context, 'Понятно');
    this.setState({ isOpen: false });
    
  }

  getToken() {
    sendAction(this.context, { action_id: "GET_TOKEN", parameters: {} })
  }


  async auth(token) {
    const resp = await axios.post(
      "https://skills.eora.ru/stoloto_proxy/auth/",
      { login: this.state.login, password: this.state.password },
      { headers: { "api-key": 'UeCDoJDFKdsA4gRS', 'Content-Type': 'application/json' } }
    )
    const parameters = resp.data.token ? { token: resp.data.token } : { error_code: resp.data.error }
    sendAction(this.context, { action_id: "AUTH", parameters })
  }
  popupControll(opened){
    this.setState({ isOpen: opened })
  }

  getText(){
    const mobileText = <AuthPageParagraphText1Main>Если у вас еще нет аккаунта, перейдите на сайт <a target="_blank" style={{color: '#12A557'}} href="https://m.stoloto.ru/registration-new/">Stoloto.ru</a> <br/> для регистрации</AuthPageParagraphText1Main>
    const otherDevicesText = <AuthPageParagraphText1Main>Если у вас еще нет аккаунта, перейдите <br/> по QR-коду на сайт <br/> для регистрации</AuthPageParagraphText1Main>
    return detectDevice()=='mobile' ? mobileText : otherDevicesText
  }

  getPolicyText(){
    const mobileText = <AuthPageParagraphText1Policy> Авторизуясь, вы соглашаетесь с <a target="_blank" style={{color: '#12A557'}} rel="noreferrer" href="https://static.stoloto.ru/files/documents/politika_v_otnoshenii_obrabotki_personalnyh_dannyh.pdf">политикой обработки данных</a></AuthPageParagraphText1Policy>
    const otherDevicesText = <AuthPageParagraphText1Policy> Авторизуясь, вы соглашаетесь с <ButtonLink style={{color: '#12A557'}} onClick={() => sendText(this.context, "политика")} >политикой обработки данных</ButtonLink></AuthPageParagraphText1Policy>
    return detectDevice()=='mobile' ? mobileText : otherDevicesText
  }

  render() {
    return (
      <>
       <StolotoTabs onlyLogoView={true} activeTab='my_tickets' />
        <AuthPageMainContainer>
          <Row style={detectDevice()=='mobile'? {justifyContent: 'space-around'}: {}}>
            <Col>
              <AuthPageCol type="rel" >
                <AuthPageTextFieldRow isFirstField={true}>
                  <TextField
                    id='login-field'
                  onKeyUp={(e) => {
                    if(e.key === "Enter" && detectDevice() == "sberBox" ){
                      setTimeout(() => { document.getElementById(`text_field_2`).focus() }, 500)
                  }
                                                   
                  }}
                    ref={(ref) => {
                      this.nameInput = ref
                    }
                    } 
                  tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                  size={'s'}
                    placeholder="Моб. тел."
                    style={{ width: '100%', }}
                    onChange={(e) => {
                      this.setState({ login: e.target.value });
                    }}
                  />
                </AuthPageTextFieldRow>
                <AuthPageTextFieldRow>
                  <TextField
                  id='text_field_2'
                  onKeyUp={(e) => {
                    if(e.key === "Enter" && detectDevice() == "sberBox" ){
                      setTimeout(() => { document.getElementById(`login_button`).focus() }, 500)
                  }
                                                   
                 }}
                  tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                  size={'s'}
                    placeholder="Пароль"
                    type="password"
                    style={{ width: '100%', }}
                    onChange={(e) => {
                      this.setState({ password: e.target.value });
                    }}
                  />
                  <AuthPageWrongPswd>{this.state?.error_text ? 'Неверный логин или пароль' : ''}</AuthPageWrongPswd>
                </AuthPageTextFieldRow>

                <Row style={{ marginBottom: "25px", width: '100%', display: 'flex', justifyContent: 'center'}}>
                  <AuthPageButtonLogin  
                  id='login_button'
                    tabIndex={this.props.messagePopupWasOpened ? -1 : 0} 
                    size={'m'} 
                    text="Войти" 
                    view="success"  
                    onClick={() => this.getToken()} 
                  />
                  <AuthPageButtonForgotPswd tabIndex={this.props.messagePopupWasOpened ? -1 : 0} size={'m'} text="Не помню пароль"  onClick={() => sendAction(this.context,  { action_id: "ACCOUNT_RECOVERY", parameters: {} })} />
                  {this.getPolicyText()}
                </Row>
              </AuthPageCol>
            </Col>
            <AuthPageTextCol >
              {detectDevice() !== 'mobile' ?
                <Row>
                  {<AuthPageImgQr src={process.env.PUBLIC_URL + "/messages/registration.webp"} />}
                </Row> : null
              }
              <Row>
                {this.getText()}
              </Row>
            </AuthPageTextCol>
          </Row>
        </AuthPageMainContainer>



      </>
    );
  }
}

export default AuthPage;

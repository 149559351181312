import React from "react";
import { detectDevice } from "@sberdevices/plasma-ui";
import { Tabs, TextBox } from "@sberdevices/plasma-ui";
import {
    Top3GameSettingsRulesTextBoxSubTitle, Top3GameSettingsRulesTextBox, Top3GameSettingsRulesCol,
    Top3GameSettingsRulesTabItem, Top3GameSettingsTabItemRulesPanel
} from './styles/top3-styles'
class Top3GameSettingsControlPanel extends React.Component {
    constructor(props) {
        super(props);
    }

    getTop3GameSettingControlPanel() {
        let rulesList = []
        let i =0
        for (let currentRule in this.props.top3RulesList) {
            i+=1

            rulesList.push(
                <Top3GameSettingsTabItemRulesPanel
                //autoFocus={(detectDevice()=='sberBox' && i==1 && !this.props.waitResult) && true}
                tabIndex={(this.props.messagePopupWasOpened || detectDevice() != 'sberBox')  ? -1 : 0}
                   
                    size={'s'}
                    key={currentRule}
                    id={this.props.idTicket}
                    settingsSelected={currentRule === this.props.gameSetting}
                    ticketWaiting={this.props?.ticketWaiting && currentRule === this.props.gameSetting}
                    onClick={() => { this.ticketRuleControl(currentRule) }}
                >
                    <div style={{ lineHeight: '18px !important' }}><p >{this.props.top3RulesList[currentRule].rusName}</p></div>
                </Top3GameSettingsTabItemRulesPanel>
            )
        }
        return <div>{rulesList}</div>
    }

    ticketRuleControl(currentRule) {
        if (!this.props?.ticketWaiting) {
            this.props.editTicketRule(currentRule)
        }
    }

    render() {
        return (
            <Top3GameSettingsRulesCol id={this.props.idTicket} >
                <Tabs id={this.props.idTicket} style={{ backgroundColor: 'rgba(255, 255, 255, 0.12)', display: 'contents', }} >
                    { <Top3GameSettingsTabItemRulesPanel isFirstItem={true} tabIndex={-1} id={this.props.idTicket}  > {!this.props?.ticketWaiting  ? "Выберите тип игры:" : 'Выбран тип игры:'}</Top3GameSettingsTabItemRulesPanel>}
                    {this.getTop3GameSettingControlPanel()}
                    {/* <Top3GameSettingsTabItemRulesPanel> */}
                        
                    <Top3GameSettingsRulesTextBox id={this.props.idTicket} >
                    <Top3GameSettingsRulesTextBoxSubTitle>{this.props.top3RulesList[this.props.gameSetting].gameRulesText}</Top3GameSettingsRulesTextBoxSubTitle>
                </Top3GameSettingsRulesTextBox> 
                        
                    {/* </Top3GameSettingsTabItemRulesPanel> */}
                </Tabs>
                {/* <Top3GameSettingsRulesTextBox id={this.props.idTicket} >
                    <Top3GameSettingsRulesTextBoxSubTitle>{this.props.top3RulesList[this.props.gameSetting].gameRulesText}</Top3GameSettingsRulesTextBoxSubTitle>
                </Top3GameSettingsRulesTextBox> */}
                
                

                    
                
            </Top3GameSettingsRulesCol>
        )
    }

}
export default Top3GameSettingsControlPanel;
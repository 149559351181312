
import {
  Col, Cell, CellIcon, CardBody,  CardContent,TextBox, TextBoxBigTitle,
} from "@sberdevices/plasma-ui";
import { TabItem, Tabs, } from "@sberdevices/plasma-ui"
import { detectDevice } from '@sberdevices/plasma-ui';
import React from "react";
import { GAMES } from "../../constants";
import { sendAction, setPageCommands, } from "../../../helpers/Assistant";

import { StolotoTabs } from "../../StolotoTabs"
import { AssistantContext } from "../../../helpers/AssistantContext"
import { formatTimeToDraw } from "../../../helpers/TextUtils";
import { GamesStyledCard, PayPopupImg, StyledCarousel, StyledCarouselItem} from "../../../style/style"
import TimerComponent from "../../../helpers/TimerComponent";
import { TextBoxTitleMessages } from "../../styles/messages-styles";
import {OnlineTicketsPageTextBoxTitle,OnlineTicketsTabsRow, OnlineTicketsPageTextBoxSubTitle, OnlineTicketsPageTextBoxSubTitleS,
  OnlineTicketsPageTextBoxTitleL, OnlineTicketsPageStyledCarouselItem, OnlineTicketsPageTabItem,
  OnlineTicketsPageTabItemFinGame, CheckTicketTabsContainer,OnlineTicketPageContainerText, OnlineTicketsTextBoxBigTitle
} from './styles/online-tickets-styles'
import OnlineTicketsVerticalCarousel from "./OnlineTicketsVerticalCarousel";
class OnlineTicketsPage extends React.Component {
  static contextType = AssistantContext;

  constructor(props) {
    super(props);
    this.state = {
      tickets: new Map(),
      index: 0,
      shoWOnlyCompletedTickets: true,
      horizontCarouselIndex: 0,
      verticalCarouselIndex: 0,
      filteredTickets: new Array(),
      checkTicketPressDisabled: false,
    }
  }


  componentDidMount() {
    this.setTickets()
    this.setTabState(false)
    this.setCommands()
  }

  getTicketObject(ticket, gameName) {
    return {
      'displayNumber': ticket.displayNumber,
      'uniqueId': ticket.uniqueId,
      'edition': ticket.drawInfo.number,
      'dateAndTime': ticket.drawInfo.date,
      'prize': ticket.prize,
      'prizePaid': ticket.prizePaid,
      'data': ticket.data,
      'status': ticket.drawInfo.status,
      'buyAt': ticket.data.date,
      'gameName': ticket.drawInfo.game,
      'additionGameName': gameName,
    }
  }

  filterTickets(tickets) {
    if (!this.state.shoWOnlyCompletedTickets) {
      return tickets.filter(ticket => ticket.status === 'COMPLETED')
    } else {
      return tickets.filter(ticket => ticket.status !== 'COMPLETED')
    }
  }

  setTickets() {
    
    let tickets = this.state.tickets;
    this.props.ticketsInfo.map((ticket) => {
      let gameName = ['6x45', '7x49', '4x20', 'oxota', '5x36plus', '5x2'].includes(ticket.drawInfo.game) ? 'sportloto' : ticket.drawInfo.game
      if (tickets.has(gameName)) {
        let newTicketArray = tickets.get(gameName).tickets
        newTicketArray.push(this.getTicketObject(ticket, gameName))
        tickets.set(gameName, { 'tickets': newTicketArray })
      } else {
        tickets.set(gameName, { 'tickets': [this.getTicketObject(ticket,gameName)],  })
      }
    })
    this.setState({ tickets: tickets })
  }

  setCommands() {

  }
  timerEnd = (gameName) => {}


  editHorisontalIndex = (index)=>{
    if(detectDevice()!=='mobile'){
    this.setState({horizontCarouselIndex: index})
    }
  }
  getTicketClassName() {
    let tr = []
    if (this.state.tickets.size > 0) {

      let newArray = this.state.filteredTickets
      if(newArray.length > 0){
      newArray.map((ticketsArray, ticketClassName) => {  
       
        tr.push(

          <OnlineTicketsPageStyledCarouselItem 

            id={ticketClassName}
            tabIndex={-1}  
            key={ticketClassName} 
            scrollSnapAlign="center"
            style={{paddingRight: '0em', paddingLeft: '0em'}}
            onFocus={(e) => { detectDevice()=='sberBox' && this.setState({horizontCarouselIndex:ticketClassName}) }} 
          >
            <OnlineTicketsVerticalCarousel
            //gameName={this.ticketsArray.gameName}
            editHorisontalIndex={this.editHorisontalIndex}
            currentHorisontalIndex={ticketClassName}
              canJumpToThis={ 
                this.state.horizontCarouselIndex-1 == ticketClassName || 
                this.state.horizontCarouselIndex+1 == ticketClassName || 
                this.state.horizontCarouselIndex == ticketClassName || 
                this.state.horizontCarouselIndex+1 == newArray.length
              } 
              messagePopupWasOpened={this.props.messagePopupWasOpened} 
              timerEnd={this.timerEnd} 
              ticketsArray={ticketsArray} 
              ticketClassName={ticketClassName}
              checkTicketPressDisabled={this.state.checkTicketPressDisabled}
              wasClickedGameCheck={this.wasClickedGameCheck}
            />
          </OnlineTicketsPageStyledCarouselItem>
       
        )
      
    })}
  }
    return tr.length > 0 ? tr : 
      <OnlineTicketsPageTextBoxTitleL shorterWidth={this.state.shoWOnlyCompletedTickets} >
        {
          this.state.shoWOnlyCompletedTickets ? 
          <TextBoxBigTitle style={{marginTop: '0px'}}>Пока нет билетов, доступных <br/> для проверки</TextBoxBigTitle> : 
          detectDevice()!='mobile' ? <TextBoxBigTitle style={{marginTop: '0px'}}>Сейчас у вас нет билетов, ожидающих <br/> розыгрыша</TextBoxBigTitle> :
          <TextBoxBigTitle style={{marginTop: '0px'}}>Сейчас у вас нет билетов,<br/> ожидающих розыгрыша</TextBoxBigTitle>
        } 
      </OnlineTicketsPageTextBoxTitleL>
  }

  setTabState(newState){
    let newArray=[]
    if(newState!=this.state.shoWOnlyCompletedTickets){
      this.state.tickets.forEach((array, key)=>{
        let filteredArray = this.filterTickets(array.tickets)
        if(filteredArray.length > 0){
          newArray.push(filteredArray)
        }    
      }
      )
      this.setState({shoWOnlyCompletedTickets: newState, filteredTickets:newArray, horizontCarouselIndex:0, verticalCarouselIndex: 0})
    }
  }

  getMyTicketsTabs() {
    return (<OnlineTicketsTabsRow>
      <Tabs style={{ height: '100%', backgroundColor: '#000000', userSelect: 'none' }}>
        <CheckTicketTabsContainer style={{userSelect: 'none' }} >
          <Col style={{background: '#000000',userSelect: 'none' }}>
            <OnlineTicketsPageTabItemFinGame 
            
            tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
            id='online_tickets_span'
              style={!this.state.shoWOnlyCompletedTickets ?
                      {backgroundColor: '#000000', color: 'rgba(255, 255, 255, 0.56)'}:
                      {backgroundColor: 'rgba(255, 255, 255, 0.2)'} 
              } 
              //data-disallowed-directions="left" 
              onClick={() =>  this.setTabState(true) } 
            >
              Доступны для проверки
            </OnlineTicketsPageTabItemFinGame>
          </Col>
          <Col style={{userSelect: 'none' }}>
            <OnlineTicketsPageTabItem 
            autoFocus={true}
            tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
            id='online_tickets_span2'
              style={!this.state.shoWOnlyCompletedTickets ?
                {backgroundColor: 'rgba(255, 255, 255, 0.2)'}: 
                {backgroundColor: '#000000', color: 'rgba(255, 255, 255, 0.56)'}  
              } 
              onClick={() =>  this.setTabState(false) } 
            >
              Ожидают розыгрыша
            </OnlineTicketsPageTabItem>
          </Col>
        </CheckTicketTabsContainer>
      </Tabs></OnlineTicketsTabsRow>)
  }

  getPaddingStart(){
    if(detectDevice()=='sberBox'){
      return '5%'
    }
    if(detectDevice()=='sberPortal'){
      return '5.85%'
    }
    if(detectDevice()=='mobile'){
      return '0em'
    }
    return '0%'
  }
  wasClickedGameCheck=()=>{
    this.setState({checkTicketPressDisabled: true})
  }
  render() {
  
    return (
      < >
        <StolotoTabs 
          messagePopupWasOpened={this.props.messagePopupWasOpened} 
          position={detectDevice()!='mobile' && 'static'} 
          activeTab='my_tickets' 
          messageShow={this.props.messageShow} 
          //style={{marginBottom: detectDevice()=='mobile' && '1em'}} 
          //waitingPage={detectDevice()=='mobile' && true}
        />
        {this.getMyTicketsTabs()}
        <OnlineTicketPageContainerText >
          
          <StyledCarousel 
            style={detectDevice()=='mobile' ? {paddingBottom: '40px'} : {}}
            paddingStart={this.getPaddingStart()} 
            //paddingEnd={detectDevice()=='mobile' && '100px'}
            detectActive={true} 
            scrollAlign="center"
            scrollSnapType='mandatory'
            scrollSnapAlign="center"
            axis={detectDevice() == 'mobile' ? 'y' : 'x'}
            detectThreshold={0.5}
            index={this.state.horizontCarouselIndex}
            onIndexChange={(index) => detectDevice()!='mobile' && this.setState({ horizontCarouselIndex: index })}
          >
            {this.getTicketClassName()}
          </StyledCarousel>
          
        </OnlineTicketPageContainerText>
        <div id='footer_shadow' ></div>
        
      </>
    );
  }
}


export default OnlineTicketsPage;
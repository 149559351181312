import { useContext, useEffect } from 'react';
import { Assistant } from '../../helpers/Assistant';
import { AssistantContext } from '../../helpers/AssistantContext';
import { navWithContainerBounds, onBackButtonEvent } from '../../helpers/Navigate';

export const addListeners = (assistant: Assistant) => {
    document.body.addEventListener('navbeforefocus', navWithContainerBounds as EventListener);
    window.onpopstate = onBackButtonEvent(assistant);
}

export const useKeyboard = () => {
    const assistant = useContext(AssistantContext);
    useEffect(() => addListeners(assistant), [assistant]);
}
import {
    Container, Card, CarouselItem, 
    CardContent, TextBoxBigTitle, TextBoxSubTitle, Button, Row, TextBoxTitle,
    
} from "@sberdevices/plasma-ui";

import { StyledCarousel } from "../../../style/style";

import styled from 'styled-components';

export const TextBoxSubTitleOrange = styled(TextBoxSubTitle)`
    color: #EF6B25; 
    margin-top: 1em; 
`;

export const TextBoxBigTitleMini = styled(TextBoxBigTitle)`
    font-size: 16px;
`;

export const CardContentButtons = styled(CardContent)`
    padding: 0px;
`;

export const CardBlackBackground = styled(Card)`
    background: #000000;
    @media (min-width: 1281px) and (max-width: 1961px) {
        width: 232px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        width: 181px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        width: 100%;
        top: 30px;
        display:flex;
        align-items: center;
    }
   
`;

export const ButtonContainer = styled(Container)`
    display: contents;

`;

export const PayPopupTextBoxBigTitle = styled(TextBoxBigTitle)`
font-weight: 700;
    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size: 48px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size: 40px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        font-size: 22px;
    }
`;

export const BuyTicketPopup = styled(Card)`
    background-color: #1A1A1A;
    transition: none;
    border-radius: 20px;
box-shadow: none;
    @media (min-width: 1281px) and (max-width: 1961px) {
        width: 800px;
        height: 400px; 
        
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        width: 800px;
        height: 400px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        width: 300px;
    }
`;

export const QtyTextBoxTitlePopup = styled(TextBoxTitle)`
font-weight: bold;
    @media (min-width: 1281px) and (max-width: 1961px) {
        display: flex;
        min-width: 200px;
        align-items: center;
        justify-content: center;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        display: flex;
        min-width: 200px;
        justify-content: center;
        align-items: center;
    }
    @media (min-width: 140px) and (max-width: 960px) {
       // margin-right: 20px;
       display: flex;
       justify-content: center;
       align-items: center;
    }
`;
export const BuyPopupRow = styled(Row)`
    width: 100%; 
    display: flex;
    transition: none;
box-shadow: none;
background-color: #1A1A1A;
    @media (min-width: 1281px) and (max-width: 1961px) {
        padding-top: 20px; 
     
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        padding-top: 20px; 
       
    }
    @media (min-width: 140px) and (max-width: 960px) {
        padding-top: 10px;
        justify-content: space-between;
    }
`;

export const QtyControlInPayPopup = styled(Button)`
    @media (min-width: 1281px) and (max-width: 1961px) {
        width: 40px;
        height: 40px; 
        padding: 40px;
        border-radius: 20px;
       // margin-right: 20px;
       //margin-right: ${props=>props.secondQtyButton && '20px'};
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        width: 40px;
        height: 40px; 
        padding: 40px;
        border-radius: 20px;
        //margin-right: ${props=>props.secondQtyButton && '20px'};
    }
    @media (min-width: 140px) and (max-width: 960px) {
        width: 40px;
        height: 40px;
    }
`;

export const BuyButtonInPopup = styled(Button)`
//border-radius: 22px;
    @media (min-width: 1281px) and (max-width: 1961px) {
        width: 290px;
        height: 80px; 
        margin-left: 20px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        width: 290px;
        height: 80px; 
        margin-left: 20px;
        
    }
    @media (min-width: 140px) and (max-width: 960px) {
        width: 100%;
        height: 40px;
        margin-top: 2em;
        border-radius: 15px;
    }
`;

export const CardContentPayPopup = styled(CardContent)`
transition: none;
box-shadow: none;
background-color: #1A1A1A;
@media (min-width: 1281px) and (max-width: 1961px) {
    padding: 2em;
    padding-top: 30px;
}
@media (min-width: 961px) and (max-width: 1281px) {
    padding: 2em;
    padding-top: 30px;
}
@media (min-width: 140px) and (max-width: 960px) {
    padding: 2em;
    padding-top: 1.5em;
}
`;

export const GetPayPopupCross = styled.span`
    font-size: 1.5em; 
    cursor: pointer; 
    position: absolute;
    z-index: 7;
    @media (min-width: 1281px) and (max-width: 1961px) {
        right: 0.5em; 
        top: 0.5em;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        right: 0.7em; 
        top: 0.7em; 
    }
    @media (min-width: 140px) and (max-width: 960px) {
        right: 0.5em; 
        top: 0.5em;
    }
`;

export const CardEditionContent = styled(Card)`
    @media (min-width: 1281px) and (max-width: 1961px) {
        width: 464px;
        height: 230px;
        border-radius: 24px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        width: 362px;
        border-radius: 17px;
        height: 202px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        width: 312px;
        height: 115px;
        //margin-left: 6px;
        margin-bottom: 12px;
        margin-top: ${props=>props.specMarginTop};
        //border-radius: 12px;
        border-radius: 0.55rem
    } 
`;

export const DrawsTextBoxBigTitleMini = styled(TextBoxBigTitleMini)`
    font-weight: 400;
    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size:${props=>props.isZeroSuperprize ? '25.5px' : '27px'};
        margin:0px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size:22px;
        margin:0px; 
    }
    @media (min-width: 140px) and (max-width: 960px) {
        font-size: ${props=>props.isZeroSuperprize ? '15px' : '16px'};
        margin:0px;
    }
`;

export const DrawsTextBoxSubTitle = styled(TextBoxSubTitle)`
    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size:23px;
        margin:0px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size:20px;
        margin:0px; 
    }
    @media (min-width: 140px) and (max-width: 960px) {
        font-size:14px;
        margin:0px;
    }
`;

export const MainCardDrawsRow= styled(Row)`
    @media (min-width: 1281px) and (max-width: 1961px) {
        min-height: 55px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        min-height: 50px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        min-height: 30px;
    }
`;

export const MainCardDrawsPopup = styled(Card)`
background-color: #1A1A1A;
background: #1A1A1A;
transition: none;
box-shadow: none;
`;

export const DrawStyledCarouselItem = styled(CarouselItem)`
    z-index: 5;
    background-color: #1A1A1A;
    transition: none;
    box-shadow: none;
    @media (min-width: 1281px) and (max-width: 1961px) {
        width: 450px;
        margin: 8px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        width: 450px;
        // padding-top: 0px;
        // padding-bottom: 0px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        width: 300px;
        padding: 0px;
    }
`;

export const CardContentPopupCarouselItem = styled(CardContent)`
background-color: #1A1A1A;
transition: none;
box-shadow: none;
    @media (min-width: 1281px) and (max-width: 1961px) {
        //padding:5px;
        padding:0px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        //width: 450px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        //width: 300px;
    }
`;
export const DrawTextBoxSubTitleOrange = styled(TextBoxSubTitleOrange)`
    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size:24px;
        margin:0px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size:20px;
        margin:0px; 
    }
    @media (min-width: 140px) and (max-width: 960px) {
        font-size:12px;
        margin:0px;
    }
`;

export const DarwContentItem = styled(Card)`
background-color: #1A1A1A;
padding: 0px;
transition: none;
box-shadow: none;
    @media (min-width: 1281px) and (max-width: 1961px) {
        margin-top: 10px;
        margin-bottom: 5px;
        margin-left: 45px;
        margin-right: 45px;
        //padding: 5px;
       // max-width: 400px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        //margin: 15px;
        // padding-top: 0px;
        // padding-bottom: 0px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        //margin: 15px;
    }
`;

export const DrawStyledCarousel = styled(StyledCarousel)`
    border-radius: 20px;
    background-color: #1A1A1A;
    padding: 0px;
    padding-bottom: 50px;
    //display: contents;
    @media (min-width: 1281px) and (max-width: 1961px) {
        height: 100%; 
        padding-bottom: 50px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        height: 100%;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        height: 100%; 
        padding-bottom: 20px;
    }
    
`;

export const CardEditionContentTextBoxBigTitle = styled(TextBoxBigTitle)`
    height: 30px; 
    //margin-top: -2px; 
    margin-bottom: 0px;
    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size:34px;
        margin:7px 0px 0px 0px;
       // letter-spacing: 2px;
        padding-bottom: 10px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size:24px;
        margin-top: 0px;
        letter-spacing: 0px;
        //margin:0px; 
    }
    @media (min-width: 140px) and (max-width: 960px) {
        font-size:17px;
        margin:0px;
    }
`;

export const CardContentEdits = styled(CardContent)`
    padding-top: 0.95rem; 
    padding-bottom: 0.55rem; 
    padding-right:0em; 
    padding-left: 0.6em;
    @media (min-width: 1281px) and (max-width: 1961px) {
        padding-top: 10px; 
        padding-bottom: 0.55rem; 
        padding-right:0px; 
        padding-left: 30px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        padding-top: 12px;
        padding-left: 26px; 
        padding-bottom: 0.25rem;  
    }
    @media (min-width: 140px) and (max-width: 960px) {
        padding-top: 5px; 
    }
`;

export const CardEditionContentRowTimerAndArrow = styled(Row)`
    justify-content: space-between;
    display: flex; 
    height: 100%;
    align-items: center;
    // align-items: flex-start;
    @media (min-width: 1281px) and (max-width: 1961px) {

    }
    @media (min-width: 961px) and (max-width: 1281px) {
        height: 50px;

    }
    @media (min-width: 140px) and (max-width: 960px) {
        margin-top: -10px;
    }
`;

export const CardEditionContentTextBoxSubTitle = styled(TextBoxSubTitle)`

    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size:23px;
        margin: 0px; 
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size:20px;
        margin-top: -2px;
        //margin-left: 3px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        font-size:12px;
        margin-top: -6px;
    }
`;

export const CardEditionContentTextBoxSubTitleOrange = styled(TextBoxSubTitleOrange)`
    margin-top: 4px;
    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size:24px;
        margin-top: ${props=>!props.drawPopup  &&  '18px'};
        margin-top: ${props => props.myTicketsScreen && '0px'};
       // margin-left: ${props => !props.gamesCategoryPage && '5px'};    
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size:18px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        font-size:12px;
        //margin-left: 1px;
        margin-left: ${props => (!props.gamesCategoryPage && !props.mobileDrawPopup) ? '5px' : '1px'};
    }
`;

export const CardEditionContentTextBoxTitleMini = styled(TextBoxBigTitleMini)`
    margin:0px;
    height: 30px;
    font-weight: 500;
    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size:27px;
        margin:0px;
        margin-top: 16px; 
        margin-bottom: 11px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size:24px;
        margin:0px; 
        margin-top: 12px;
        margin-bottom: 6px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        font-size:15px;
        margin:0px;
        margin-bottom: 6px;
    }
`;


export const EditionActionsPopupCross = styled(Button)`
    width: 20px;
    font-size: 1.5em; 
    cursor: pointer; 
    position: absolute;
    background-color: #1A1A1A;
    @media (min-width: 1281px) and (max-width: 1961px) {
        //padding-right: 0px;
        right: 0.3em; 
        top: 0.3em;
        width: 10px;
        height: 10px;
        
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        width: 20px;
        height: 20px;
        right: ${props=>props.right ? props.right : '17px' }; 
        top: 13px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        right: 9px;
        top: 0.0em;
    }
`;

export const StyledCarouselCard = styled(Card)`
    &:focus{
        border: none;
        outline: none;
        border-color: inherit;
    }
    @media (min-width: 1281px) and (max-width: 1961px) {
        margin-left: 12px;
        margin-bottom: 12px;
    }
    @media (min-width: 960px) and (max-width: 1281px){
        margin-left: 10px;
        margin-bottom: 10px;
        border-radius: 18px;
    }
    
    @media (min-width: 640px ) and (max-width: 800px) {
        margin-left: ${props=>props.moreThenOneTicket && '6px'};
        border-radius: 10px;
    }
    @media (min-width: 140px ) and (max-width: 500px) {
        margin-left: ${props=>props.moreThenOneTicket && '0px'};
        border-radius: 10px;
    }

`;
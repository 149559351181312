import { Col, TextBox, TextBoxSubTitle, TabItem } from "@sberdevices/plasma-ui";
import styled from "styled-components";

export const Top3GameSettingsRulesTextBoxSubTitle = styled(TextBoxSubTitle)`
    color: white;
    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size: 20px;
        line-height: 26px;
        padding-left: 16px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        font-size: 10px;
        line-height: 13px;
    }  
`;

export const Top3GameSettingsRulesTextChoise= styled(TextBoxSubTitle)`
    color: white;
    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size: 24px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size: 20px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        font-size: 12px;
    }  
`;

export const Top3GameSettingsRulesTextBox= styled(TextBox)`
    
    @media (min-width: 1281px) and (max-width: 1961px) {
        width: 375px; 
        //padding-left: 48px;
        padding-top: 24px;
        
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        width: 293px; 
        margin-left: 13px; 
        //margin-top: 1.2em;
        font-size: 20px;
        padding-top: 27px;
        line-height: 20px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        width: 142px; 
        padding-left: 16px;
        padding-top: 1px;
        //margin-top: 0.7em;
    }  
`;

export const Top3GameSettingsRulesCol= styled(Col)`
    @media (min-width: 1281px) and (max-width: 1961px) {
        width: 400px; 
        margin-left: -15px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        width: 341px; 
        margin-top: -10px;
        margin-left: -7px;
        //padding-top: 20px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        width: 174px; 
        margin-top: 5px;
        //margin-left: -16px;
    }  
`;

export const Top3GameSettingsRulesTabItem= styled(TabItem)`
    color: white;
    backgroundColor: none;
    height: 28px;
    font-weight: 400;
    @media (min-width: 1281px) and (max-width: 1961px) {
        //padding-bottom: 50px; 
        padding-bottom: 29px;
        font-size: 24px; 
        padding-left: 0px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        padding-bottom: 30px; 
        font-size: 20px;  
        margin-left: -34px !important;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        //padding-bottom: 30px; 
        font-size: 12px;  
        margin-left: -14px !important;
        margin-top: -2px;
    }  
`;

export const Top3GameSettingsTabItemRulesPanel= styled(TabItem)`
    color: ${props => props.settingsSelected ? 'white' : 'rgba(255,255,255,0.56)'  };
    background-color: ${props => props.ticketWaiting ? '#12A557' : props.settingsSelected ?'#4C4C4C' :'#1F1F1F'  };
    height: 28px; 
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-left: 5px;
    font-weight: 400;
    @media (min-width: 1281px) and (max-width: 1961px) {
        //padding-bottom: 30px; 
        padding: 10px;
        font-size: 24px;  
        padding-top:22px;
        //padding-left: 22px;
        padding-bottom: 22px;
        //margin-left: 40px;
        margin-top: 8px;
        //width: 100%;
        
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        //padding-bottom: 10px; 
        //padding-top:10px;
        font-size: 18px; 
        margin-top: 10px; 
        //width: 275px;
        padding: 10px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        //padding-bottom: 30px; 
        font-size: 12px;  
       margin-left: 5px !important;
        margin-top: 0px;
        padding: 10px;
    }  
`;
import { IconTicket } from "@sberdevices/plasma-icons"
import { TabItem, Tabs, Row } from "@sberdevices/plasma-ui"
import { sendText } from "../helpers/Assistant";
import { AssistantContext } from "../helpers/AssistantContext";
import { IconLogout } from '@sberdevices/plasma-icons';
import { detectDevice } from '@sberdevices/plasma-ui';
import StolotoTabsMobile from './StolotoTabsMobile'

import { StolotoTabsTabItem, StolotoTabsImageAppName, StolotoTabsMainRow, StolotoTabsList, StolotoTabsImageLogout,StolotoTabsTabItemsTextContentWrapper } from './styles/stoloto-tabs-styles'
import { getPositionOfLineAndCharacter } from "typescript";
import { type } from "os";
type TabId = 'lotteries' | 'my_tickets' | 'check_tickets' | 'app_logo' | 'exit';
type StolotoTabsProps = {waitingPage: boolean, messagePopupWasOpened: boolean, isShowResultPage: boolean, position: string | undefined, background: string,specialStyles: object,imageLogout?: boolean, uncheckedTickets?: boolean, activeTab: TabId, payMoney?: string, showPayText?: boolean, onlyLogoView?: boolean , messageShow?: boolean }
type StolotoTabProps = {messagePopupWasOpened: boolean, imageLogout?: boolean, id: TabId, activeTab: TabId, textOnClick: string, children: React.ReactNode, messageShow?: boolean }
type TabsRawProps = {position: string | undefined, }
export function StolotoTabItem(props: StolotoTabProps) {
    const { activeTab, id, textOnClick, children, ...other } = props;
    
    return <AssistantContext.Consumer>
        {
            assistant =>
                <StolotoTabsTabItem 
                    tabIndex={props.messagePopupWasOpened ? -1 : 0} 
                    imageLogout={props.imageLogout} 
                    isActive={activeTab === id} 
                    onClick={() => detectDevice() !== "sberBox" &&  sendText(assistant, textOnClick)} 
                    onKeyUp={(e) =>{
                        e.key === "Enter" &&
                        sendText(assistant, textOnClick)} 
                      }
                    {...other}
                >
                    {children}
                </StolotoTabsTabItem>
        }
    </AssistantContext.Consumer>
}

export function StolotoTabs(props: StolotoTabsProps ) {
    const position = props?.position ? props.position : 'fixed'
    return detectDevice() === 'mobile' ? <StolotoTabsMobile  onlyLogoView ={props.onlyLogoView} waitingPage={props.waitingPage} isShowResultPage={props.isShowResultPage} showPayText={props.showPayText} payMoney={props.payMoney}/> : 
    <StolotoTabsMainRow position={position}>
        <StolotoTabsList style={props.specialStyles}>

        <div>
            <StolotoTabsImageAppName src={process.env.PUBLIC_URL + 'AppName.png'} />
        </div>

       {!props.onlyLogoView && <StolotoTabsTabItemsTextContentWrapper >
            <StolotoTabItem
                id='lotteries'
                messageShow={props.messageShow}
                activeTab={props.activeTab}
                textOnClick='Основной экран'
                messagePopupWasOpened={props.messagePopupWasOpened}
                //data-disallowed-directions="left"
                
            >
                Лотереи
            </StolotoTabItem>
            <StolotoTabItem
            messageShow={props.messageShow}
                id='my_tickets'
                activeTab={props.activeTab}
                textOnClick='открой мои билеты'
                messagePopupWasOpened={props.messagePopupWasOpened}
            >
                Мои билеты {props.uncheckedTickets ? <IconTicket size="xs" /> : null}
            </StolotoTabItem>
            <StolotoTabItem
                messageShow={props.messageShow}
                id='check_tickets'
                activeTab={props.activeTab}
                textOnClick='Проверить билеты'
                messagePopupWasOpened={props.messagePopupWasOpened}
                
            >
                Проверка билета
            </StolotoTabItem>
            <div  style={ detectDevice()=='sberBox' ? { marginLeft: '2em', marginRight: '3em' }: {marginLeft: '2em'}}>
                <StolotoTabItem
                messageShow={props.messageShow}
                    id='exit'
                    activeTab={props.activeTab}
                    textOnClick='Выйти из аккаунта'
                    //data-disallowed-directions="right"
                    imageLogout={true}
                    messagePopupWasOpened={props.messagePopupWasOpened}
                >

                    <StolotoTabsImageLogout  src={process.env.PUBLIC_URL + 'logout.png'}  />
                </StolotoTabItem>
            </div>
        </StolotoTabsTabItemsTextContentWrapper>}
    </StolotoTabsList></StolotoTabsMainRow>
}
import React from "react";
import PreparedTicketConstructor from "../bingo/ticket/PreparedTicketConstructor"
import { StyledCarouselCard, } from "./styles/common-elements-styles"
import { Row, Col, Carousel, CarouselCol, detectDevice } from "@sberdevices/plasma-ui";
class TicketsCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tickets: this.props.tickets,
            carouselIndex: 0,
            touchPixelCount: 0
        };
        this.initial_focus = null;
    }

    componentDidMount() {
      if(!this.props.ticketWaiting){
        this.initial_focus.focus();
      }
        
    }

    getActiveConstructor(ticket, key, gameName, touchIndex) {

        return (
            <PreparedTicketConstructor
            ticketWaiting={this.props.ticketWaiting} 
            touchIndex={touchIndex}
                gameName={gameName}
                ticketInfo={ticket} key={key}
                ticketWasSelect={this.state.tickets.has(ticket['barCode']) || this.props.ticketWaiting}
            />
        )
    }

    toggleTicket = (barcode, carouselIndex) => {
        if(!this.props.ticketWaiting){
            let tickets = this.props.tickets
            if (tickets.has(barcode)) {
    
                tickets.delete(barcode)
            } else {
                tickets.add(barcode)
            }
            this.props.addActiveTicketBarcode(tickets)
            this.setState({ tickets: tickets, carouselIndex: carouselIndex })
        }

    }

    render() {
        const paddingStart = {'sberPortal': this.props.ticketWaiting ? '50%':'40px', 'sberBox': this.props.ticketWaiting ? '50%':'2.5em', 'mobile': this.props.ticketWaiting ? '50%':'1.5em'}
        return (
          <>
            <Carousel
              paddingStart={paddingStart[detectDevice()]}
              paddingEnd={this.props.ticketWaiting ? '50%':"2.5em"}
              index={this.state.carouselIndex}
              axis='x'
              onIndexChange={(index) => console.log(index)}
              detectThreshold={0.5}
              scrollSnapType="mandatory"
              key={1}
              detectActive={true}
              onDetectActiveItem={(index) => {
              }}
            >
              {this.props.ticketsInfo.map((chunk, chunkKey) => (
                <Col className='turg' aria-roledescription="slide" key={chunkKey}>
                  {chunk.map((ticket, key) => (
                    <CarouselCol
                      style={{ borderRadius: "0.55rem" }}
                      id={key}
                      key={key}
                      scrollSnapAlign="center"
                    >
                      <StyledCarouselCard
                      roundness={'8'}
                        outlined
                        ref={(card) => { if (!this.initial_focus) { this.initial_focus = card } }}
                        tabIndex={this.props.messagePopupWasOpened ? -1 : 0}
                        id={chunkKey}
                        touchIndex={chunkKey}
                        onFocus={(e) => {
                          this.setState({ carouselIndex: e.target.id });
                        }}
                        onKeyUp={(e) =>
                          e.key === "Enter" &&
                          this.toggleTicket(ticket["barCode"], chunkKey)
                        }
                        onClick={() => detectDevice() !== "sberBox" && this.toggleTicket(ticket["barCode"], chunkKey)}
                        style={
                          this.state.tickets.has(ticket["barCode"]) || this.props.ticketWaiting
                            ? { backgroundColor: "white", marginTop: "8px", marginRight: '8px' }
                            : { marginTop: "8px" }
                        }
                      >
                        {this.getActiveConstructor(
                          ticket,
                          key,
                          this.props.gameName,
                          chunkKey
                        )}
                      </StyledCarouselCard>
                    </CarouselCol>
                  ))}
                </Col>
              ))}
            </Carousel>
          </>
        );
    }
}


export default TicketsCarousel;
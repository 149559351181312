import React from "react";
import { IconLogout, IconNavigationArrow } from '@sberdevices/plasma-icons';
import {getDisallowedDirections} from '../../helpers/Navigate'
import {CardEditionContent, DrawsTextBoxBigTitleMini, DrawsTextBoxSubTitle, 
    CardEditionContentTextBoxBigTitle, DrawStyledCarouselItem,DrawTextBoxSubTitleOrange, 
    DrawStyledCarousel, CardContentEdits, CardEditionContentTextBoxSubTitle,
    CardEditionContentTextBoxSubTitleOrange, CardEditionContentTextBoxTitleMini,
    EditionActionsPopupCross, DarwContentItem, CardEditionContentRowTimerAndArrow,
    CardContentPopupCarouselItem, MainCardDrawsPopup, MainCardDrawsRow} from './styles/common-elements-styles'
import {
     MessageContainerDraw, MessageDrawContent, ArrowButtonStyles,
} from "../../style/style"
import { IconChevronRight, IconChevronLeft, IconClose, IconCross } from '@sberdevices/plasma-icons';
import {AssistantContext} from "../../helpers/AssistantContext";
import {CardContent, Row, Button, detectDevice, Card, LineSkeleton} from "@sberdevices/plasma-ui";
import {GAMES} from "../constants";
import {formatTimeToDraw, formatMoney} from "../../helpers/TextUtils";
import { sendAction, sendText, setPageCommands } from "../../helpers/Assistant";
import TimerComponent from '../../helpers/TimerComponent'
import { isRefFromPopup } from "../../helpers/RefHelpers";

class EditionActions extends React.Component {
    static contextType = AssistantContext;
    constructor(props) {
        super(props);
        this.state = {
            tick: false,
            draws: this.props.drawInfo.draw,
            isOpen: false ,
            editionInfo: this.props.editionInfo,
            activeDrawInfo: this.props.activeDrawInfo,
            popapCarouselIndex: 0,
        }
        this.nameInput = null
    }

    componentWillReceiveProps(nextProps){
        if(!nextProps.messagePopupWasOpened && this.state.isOpen){
            this.closePopup()
        }
    }

    componentDidMount() {
        this.setCommands()
    }
    componentWillUnmount(){
        setPageCommands(this.context, {
          
            }
        );
    }

    setNewDrawInfo(action){
        if(action.payload.draws[this.props.gameName].length !==0){
            const draws = action.payload.draws[this.props.gameName]
            const editionInfo = draws.map((draw) => (
                {
                    'drawSuperprise':draw.superPrize, 
                    'drawNumber': draw.number, 
                    'drawId': draw.id,
                    'drawDate': draw.stopSalesDate 
                }
            ))
            this.setState({
                ...this.state, 
                draws: draws[0],
                activeDrawInfo: editionInfo[0],
                editionInfo
            })
            this.props.updateActiveDraw(editionInfo[0])
            this.forceUpdate()
        } else {
            setTimeout(() => this.timerEnd(), 1000)
        }
    }

    startSelection() {
        if(!this.props.ticketWaiting){
            sendAction(this.context, { action_id: "CHANGE_DRAW_START", parameters: {} })
            window.lastFocusedElement = document.activeElement
            
            this.setState({isOpen: true}, ()=>{this.nameInput.focus(); this.props.messagePopupWasOpenedFunction(true)})
            
        }
    }

    selectEdition(drawId) {
        sendAction(this.context, { action_id: "CHANGE_DRAW", parameters: { 'drawId': drawId } })
        this.props.editDrawNumber(drawId)
        const currentDraw = this.state.editionInfo.filter(obj => {
            return obj.drawNumber === drawId
        })
        this.setState({isOpen: false, activeDrawInfo:currentDraw[0]})
    }

    getGameSettings(gameId) {
        return GAMES.find(function (game, index) {
            if (game.id === gameId) {
                return game;
            }
        });
    }

    getDrawSuperprise(draw){
        const superPrise = draw.drawSuperprise
        return  superPrise>0 ? 'Суперприз ' + formatMoney(draw.drawSuperprise) : 'Джекпот объявим позже'
    }

    getDisallowedForPopupDraws(itemIndex, carouselLength){
        let disallowedArray = ['left', 'right'] 
        return (itemIndex === carouselLength || carouselLength==1) ? ['left', 'right','down'] : disallowedArray
    }

    closePopup(){
       // 
        // this.props.messagePopupWasOpenedFunction(false)
        sendText(this.context, 'CLOSE_MESSAGE')      
        this.setState({isOpen: false},()=>this.props.messagePopupWasOpenedFunction(false))
    }

    getDraws(){

        return(
            <MainCardDrawsPopup >
                
                <MainCardDrawsRow>
                    <EditionActionsPopupCross 
                    className="functional-popup"
                        square={true} 
                        size={'s'} 
                        ref={(input) => { this.nameInput = input; }}
                        //data-disallowed-directions={['up', 'left', 'right']} 
                        tabIndex={0} 
                        onClick={(e)=> detectDevice() !== "sberBox" &&  this.closePopup()}
                        onKeyUp={(e) =>
                            e.key === "Enter" &&
                            this.closePopup()
                          }
                        >
                            <IconCross></IconCross>
                    </EditionActionsPopupCross>
                </MainCardDrawsRow>
                
                <DrawStyledCarousel index={this.state.popapCarouselIndex} detectActive={true} detectThreshold={0.5} scrollSnapType='mandatory' axis="y">
                    {this.state.editionInfo.map((draw, key) => (
                        <DarwContentItem 
                        className="functional-popup"
                            onFocus={()=>this.setState({popapCarouselIndex: key})} 
                            onClick={(e) => { this.selectEdition(draw.drawNumber) }} 
                            id={key} 
                            key={key} 
                            tabIndex={0}
                            roundness={'12'}
                        >
                            <DrawStyledCarouselItem 
                                key={key} 
                                id={key} 
                                style={{display: 'flex', justifyContent: 'center'}} 
                                //onClick={()=>{this.selectEdition(draw.drawNumber)}}
                            >
                                
                                <CardContentPopupCarouselItem  >
                                    <DrawsTextBoxBigTitleMini isZeroSuperprize={draw.drawSuperprise==0}>{this.getDrawSuperprise(draw)}</DrawsTextBoxBigTitleMini>
                                    <DrawsTextBoxSubTitle>Тираж {draw.drawNumber}</DrawsTextBoxSubTitle>
                                    <TimerComponent mobileDrawPopup={detectDevice()=='mobile'} drawPopup={true}  timerEndReaction={()=>{}} targetTime={draw.drawDate}/> 
                                </CardContentPopupCarouselItem>
                                
                            </DrawStyledCarouselItem>
                        </DarwContentItem>
                    ))}
                </DrawStyledCarousel>
            </MainCardDrawsPopup>

        )
    }
    getSpecMargins(){
        if(detectDevice()=='mobile'){
            if(['keno2'].includes(this.props.gameName)){
                return {marginTop: '16px', marginBottom: '23px'}
            }
           
        }
    }
    setCommands(){
        this.context.page_commands['SEND_DRAWS'] = (action) => {
            this.setNewDrawInfo(action)
        };
    }
    timerEnd = ()=>{
        if(!this.props.ticketWaiting) 
        {
            sendAction(this.context,  { action_id: "GET_DRAWS", parameters: {"game_ids": [this.props.gameName]} })
            this.setCommands()
        }
    }
    render() {
        return (
            <>
            <MessageContainerDraw style={this.state.isOpen ? { display: 'grid',justifyItems: 'center', justifyContent: 'center',  alignContent: 'center'} : { display: 'none' }}>
                <MessageDrawContent  >
                    
                    {this.getDraws()}       
                </MessageDrawContent>
            </MessageContainerDraw>
            <CardEditionContent  
                id="open_draws_papup_button"
                tabIndex={this.props.messagePopupWasOpened ? -1 : 0} 
                style={this.getSpecMargins()} 
                onClick={()=>{detectDevice()!=='sberBox' && this.startSelection()}}
                onKeyUp={(e) =>
                    e.key === "Enter" &&
                    this.startSelection()
                  }
                >
                <CardContentEdits >
                        <CardEditionContentTextBoxBigTitle>{this.getGameSettings(this.props.gameName).rusName}</CardEditionContentTextBoxBigTitle>
                        {this.state.activeDrawInfo.drawSuperprise==0 ? <CardEditionContentTextBoxTitleMini>Суперприз узнаете позже</CardEditionContentTextBoxTitleMini> : <CardEditionContentTextBoxTitleMini>Суперприз {formatMoney(this.state.activeDrawInfo.drawSuperprise)}</CardEditionContentTextBoxTitleMini>}
                        <CardEditionContentTextBoxSubTitle>Тираж {this.state.activeDrawInfo.drawNumber}</CardEditionContentTextBoxSubTitle>
                        <CardEditionContentRowTimerAndArrow >
                            <TimerComponent ticketWaiting={this.props.ticketWaiting} timerEndReaction={()=>this.timerEnd()} targetTime={this.state.activeDrawInfo.drawDate}/>
                            { (detectDevice() !== 'sberBox' && !this.props.ticketWaiting) && <div >
                                <ArrowButtonStyles size="s"  square={true} ><IconChevronRight  size='xs'></IconChevronRight></ArrowButtonStyles > 
                            </div>}
                        </CardEditionContentRowTimerAndArrow>
                </CardContentEdits>
            </CardEditionContent>
            </>
        )
    }

}


export default EditionActions;
import React from "react";
import { addGeneralCommand } from "../../helpers/Assistant";
import { AssistantContext } from "../../helpers/AssistantContext";

import ControlTicketForm from "./ControlTicketForm";
import { RULES_TOP3 } from "../constants";
import { sendAction } from "../../helpers/Assistant";
import {setPageCommands} from "../../helpers/Assistant"
class ScreenTop3 extends React.Component {
    static contextType = AssistantContext;

    constructor(props) {
        super(props);
        this.state = {
            tickets: this.props?.selectedTicketNumbers || [{ 'key': 0, 'selectedNumbersFirstTicket': new Map(), 'gameSetting': 'exactly3', 'sumForTicket': 0, }],
            selectedTicketKey: 0,
            withSelectorControl: false,
        }
    }

    componentDidMount() {
        addGeneralCommand(this.context, 'DRAW_CHANGE', (action) => {
            this.setState(action.payload);
        })
    }
    componentWillUnmount(){
        setPageCommands(this.context, {
          
            }
        );
    }
    addNewTicket=() => {
        let tickets = this.state.tickets
        tickets.push({ 'key': tickets.length, 'selectedNumbersFirstTicket': new Map(), 'gameSetting': 'exactly3', 'sumForTicket': 0,   })
        this.setState({ tickets: tickets, withSelectorControl: true })
    }

    editTicketRule = (ticketNumber, newGameSettingValue) => {
        let tickets = this.state.tickets
        for (let ticket in tickets) {
            if (tickets[ticket].key === ticketNumber) {
                tickets[ticket].gameSetting = newGameSettingValue
                tickets[ticket].selectedNumbersFirstTicket = new Map()
                tickets[ticket].sumForTicket = 0
            }
        }
        this.setState({ tickets: tickets })

    }

    selectTicket = (value) => {
        this.setState({ selectedTicketKey: value+1 })
    }

    getTop3Numbers(ticket) {
        let currentTicket = this.state.tickets[ticket]['selectedNumbersFirstTicket']
        let numbers = []
        currentTicket.forEach((curTicket, key) => {
            numbers.push(((key + 1) + (curTicket.rowNumber * RULES_TOP3[this.state.tickets[ticket].gameSetting]['firstTicket']['cols'])))
        })
        return numbers
    }

    setRandomNumbers = (ticketKey) => {
        let resultRandom = []
        let resultTop3Ticket = new Map()
        if (['combo', 'any3', 'exactly3Plusany3'].includes(this.state.tickets[ticketKey]['gameSetting'])) {
            //генерируем два УНИКАЛЬНЫХ псевдослучайных числа и после этого обавляем к ним еще псевдослучайное число. 
            //надо делать именно так, потому что в этом случае только два числа могут совпадать(а могут и нет) 
            let setOfFirstTicket = new Set()
            while (setOfFirstTicket.size <= 2) {
                const randomNum = Math.floor(10 * Math.random())
                setOfFirstTicket.add(randomNum)
            }
            resultRandom = Array.from(setOfFirstTicket);
            resultRandom.push(Math.floor(10 * Math.random()))
        } else {
            //в этом случае генерируем сразу все псевдослучайные числа потому что они все могут совпадать(а могут и нет)
            while (resultRandom.length < RULES_TOP3[this.state.tickets[ticketKey ]['gameSetting']].limitationInRow) {
                const randomNum = Math.floor(10 * Math.random())
                resultRandom.push(randomNum)
            }
        }
        let resultColumnsRandom = []
        let randomSits = [0, 1, 2]
        let newLength = []
        if (RULES_TOP3[this.state.tickets[ticketKey]['gameSetting']].colsLimitation.length !== 0) {
            //обрезаем "лишние" столбцы
            randomSits.splice(RULES_TOP3[this.state.tickets[ticketKey]['gameSetting']].colsLimitation[0], 1)
        }
        //перемешиваем для большей случайности
        for (let random in randomSits) {

            if (randomSits[random] != undefined) {
                newLength.push(randomSits[random])
            }
        }
        newLength.sort(() => Math.random() - 0.5);

        //заполняем новый порядок
        for (let sit in newLength) {
            resultColumnsRandom[newLength[sit]] = resultRandom[sit]
        }
        //подготавливаем данные
        for (let random in resultColumnsRandom) {
            if (resultColumnsRandom[random] != undefined) {
                resultTop3Ticket.set(parseInt(random), { 'rowNumber': resultColumnsRandom[random], 'value': 9 - resultColumnsRandom[random] })
            }
        }
        let tickets = this.state.tickets
        //сэтим нужный тикет
        tickets[ticketKey].selectedNumbersFirstTicket = resultTop3Ticket
        //this.getPaySum()
        
        let ticketSummary = this.getPaySumForTicket(tickets[ticketKey].selectedNumbersFirstTicket, ticketKey)
        tickets[ticketKey]['sumForTicket'] = ticketSummary['sumToPay']
        tickets = ticketSummary['tickets']
        this.setState({ tickets: tickets })
    }


    isRowHaveFreeSpace(ticket, limitation, limitsInTicket, rowNumber) {
        //метод проверяет "вмещается" ли "клик" в строчку (есть режимы в которых в одной строке могут быть 
        //только два активированных поля, есть те в которых 1 или 3)
        let rows = []

        const counts = {};

        for (let row of ticket.values()) {
            rows.push(row.rowNumber)
        }

        rows.forEach((x) => {
            counts[x] = (counts[x] || 0) + 1;
        });
        if (rows.length >= limitsInTicket) {
            return false
        }
        for (let row in counts) {
            if (counts[row] >= limitation && row == rowNumber) {
                return false
            }
        }
        return true

    }

    getPaySumForTicket(ticket, ticketKey) {
        const betCost = this.props.gameInfo.draw.betCost
        let sumToPay = 0
        let tickets = this.state.tickets
        if (tickets[ticketKey].gameSetting !== 'combo') {
            if (ticket.size === RULES_TOP3[tickets[ticketKey]['gameSetting']]['firstTicket']['restrictions']['max']) {
                sumToPay += sumToPay + betCost
            }
        } else {
            if (ticket.size === RULES_TOP3[tickets[ticketKey]['gameSetting']]['firstTicket']['restrictions']['max']) {
                let rows = []

                const counts = {};

                for (let row of ticket.values()) {
                    rows.push(row.rowNumber)
                }

                rows.forEach((x) => {
                    counts[x] = (counts[x] || 0) + 1;
                });

                for (let row in counts) {
                    if (counts[row] === 2) {
                        sumToPay += sumToPay + 3 * betCost
                        return {'sumToPay': sumToPay, 'tickets': tickets}
                    }
                }
                
                sumToPay += 6 * betCost
                
            }
        }


        return {'sumToPay': sumToPay, 'tickets': tickets}
    }

    getPaySum() {
        return this.state.tickets.reduce((prev, cur) => prev + cur.sumForTicket, 0);
    }

    setTicketNumber = (currentNumber, ticketNumber, colNumber, rowNumber) => {
        
        let tickets = this.state.tickets
        const currentTicketIndex = tickets.findIndex(t => t.key == ticketNumber);
        let currentTicket = tickets[currentTicketIndex]
        const limitationInRowForTicket = RULES_TOP3[currentTicket.gameSetting].limitationInRow
        const limitationForTicket = RULES_TOP3[currentTicket.gameSetting].firstTicket.restrictions.max
        let ticketChoise = currentTicket['selectedNumbersFirstTicket'].get(colNumber)
        
        //в выбранном столбце уже есть активный квадрат по этому его сначала надо удалить, прежде чем совершать какие то действия 
        currentTicket['selectedNumbersFirstTicket'].delete(colNumber)
        //currentTicket['sumForTicket'] = 0

        if (this.isRowHaveFreeSpace(currentTicket['selectedNumbersFirstTicket'], limitationInRowForTicket, limitationForTicket, rowNumber)) {
            currentTicket['selectedNumbersFirstTicket'].set(colNumber, { 'rowNumber': rowNumber, 'value': currentNumber })
            //currentTicket['sumForTicket'] = this.getPaySumForTicket(currentTicket['selectedNumbersFirstTicket'], currentTicketIndex)['sumToPay']
            let ticketSummary = this.getPaySumForTicket(currentTicket['selectedNumbersFirstTicket'], currentTicketIndex)
            currentTicket['sumForTicket']   = ticketSummary['sumToPay']
            tickets = ticketSummary['tickets']
        } else {
            if (ticketChoise !== undefined && ticketChoise.value != currentNumber) {
                currentTicket['selectedNumbersFirstTicket'].set(colNumber, { 'rowNumber': ticketChoise.rowNumber, 'value': ticketChoise.currentNumber })
                //currentTicket['sumForTicket'] = this.getPaySumForTicket(currentTicket['selectedNumbersFirstTicket'], currentTicketIndex)['sumToPay']
                let ticketSummary = this.getPaySumForTicket(currentTicket['selectedNumbersFirstTicket'], currentTicketIndex)
                currentTicket['sumForTicket']   = ticketSummary['sumToPay']
                tickets = ticketSummary['tickets']
            }
        }
        if (ticketChoise !== undefined && ticketChoise.value == currentNumber) {
            currentTicket['selectedNumbersFirstTicket'].delete(colNumber)
            currentTicket['sumForTicket'] = 0
        }

        this.setState({ tickets: tickets })
    }

    removeTicket=(ticketId)=>{
        let currentTickets = this.state.tickets
        if(currentTickets.length>1){
            currentTickets.splice(ticketId,1)
            this.setState({tickets: currentTickets})
        }
         
    }

    clearTicket=(ticketId)=>{
        let currentTickets = this.state.tickets
        currentTickets[ticketId].selectedNumbersFirstTicket= new Map()
        currentTickets[ticketId].gameSetting= 'exactly3'
        currentTickets[ticketId].sumForTicket = 0
        this.setState({tickets: currentTickets})
    }

    getTicketsQty(){
        let ticketsQty = 0
        this.state.tickets.map((ticket, ind)=>{
            if(ticket.sumForTicket > 0){
                ticketsQty = ticketsQty+1
            }
        })
        return ticketsQty
    }

    render() {
        return (
            <>
                <ControlTicketForm
                    messagePopupWasOpened={this.props.messagePopupWasOpened}
                    messagePopupWasOpenedFunction = {this.props.messagePopupWasOpenedFunction}
                    
                    messageShow={this.props.messageShow} 
                    withSelectorControl={true}
                    paySum={this.getPaySum()}
                    gameInfo={this.props.gameInfo}
                    addNewTicket={this.addNewTicket}
                    tickets={this.state.tickets}
                    selectedTicketKey={this.state.selectedTicketKey}
                    setTicketNumber={this.setTicketNumber}
                    gameName={this.props.gameInfo.name}
                    setRandomNumbers={this.setRandomNumbers}
                    selectTicket={this.selectTicket}
                    editTicketRule={this.editTicketRule}
                    top3RulesList={RULES_TOP3}

                    ticketWaiting={this.props?.ticketWaiting}
                    removeTicket={this.removeTicket}
                    clearTicket={this.clearTicket}
                    ticketQty={this.getTicketsQty()}

                    waitResult={this.props?.ticketWaiting}
                />
            </>
        );
    }
}


export default ScreenTop3;
import React from "react";
import { Button, Row, TextBox, detectDevice, Card, Col } from "@sberdevices/plasma-ui";
import { MessageContainerDraw, MessageDrawContent, } from '../../../../style/style'
import { IconChevronRight, IconChevronLeft, IconClose, IconCross } from '@sberdevices/plasma-icons';
import {
    KenoThirdGameStyledCarouselItem, KenoThirdGameStyledCarousel, KenoThirdGameTabs,
    KenoThirdGameTabItem, KenoThirdGameTextBoxSubTitle, KenoThirdGameOutlineGreenwrapper, KenoThirdGameTextBoxSubTitleMobile,
    KenoThirdGameTabItemMobileListCols, KenoThirdGameTextBoxRule
} from './styles/keno-styles'
import { MobileTabsTextBoxTitleMini } from '../../../styles/tabs-mobile-styles'

class KenoThirdGameCol extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <KenoThirdGameTabItem
                    tabIndex={-1}
                    waitResult={this.props.waitResult}
                    outlined={this.props.outlined}
                    isFirstTabInCol={this.props.isFirstTabInCol}
                    isActive={this.props.isActive}
                    activeCol={this.props.activeCol}>
                    {this.props.colNumber}
                </KenoThirdGameTabItem>

            </>
        )
    }

}
const KenoThirdGameColMemo = React.memo(KenoThirdGameCol);
export default KenoThirdGameColMemo;
import styled from 'styled-components';
import { TabItem,TextBoxBigTitle } from "@sberdevices/plasma-ui";

export const MessageContainer = styled.div`
z-index:5;
  position: fixed;
  
  height: 350px;
  //top: 25%;
  //margin-left: 1.5em;
  display: grid;
  background-color: #1A1A1A;
  border-radius: 20px;
`;

export const Content = styled.div`
  grid-area: content;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const TabsItemMobile = styled(TabItem)`
    margin-top: 15px;
    min-width: 215px;
    background-color: ${props => props?.tabItemActive ? '#1A1A1A ' : 'var(--plasma-colors-surface-liquid01,rgba(255,255,255,0.06))'};

`;

export const MobileTabsTextBoxTitleMini = styled(TextBoxBigTitle)`

    font-weight: 400;

    @media (min-width: 140px) and (max-width: 960px) {
        font-size:12px;
        padding-right: 10px;
        color: rgba(255, 255, 255, 0.56);
    }
`;
export function getRandomSetOfNumbers(maxSize,maxSquareViewed){
    console.log(maxSquareViewed)
    let setOfTicketNumbers = new Set()
    while (setOfTicketNumbers.size <= maxSize) {
        const maxNumber = maxSquareViewed
        const randomNum = Math.floor(maxNumber * Math.random())
        const currentSyber = randomNum != 0 ? randomNum : randomNum + 1
        setOfTicketNumbers.add(currentSyber)
    }
    return setOfTicketNumbers
}


export  function getScreenMode(){
    const screen_width = window.screen.width
    if(screen_width  > 955){
        return 'full'
    }
    if(screen_width  > 635 && screen_width  < 955){
        return 'tablet'
    }
    if(screen_width  < 635){
        return 'mobile'
    }
}


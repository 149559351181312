
import {
    Container,  Button, detectDevice
} from "@sberdevices/plasma-ui";
import React from "react";

import { sendAction } from "../../../helpers/Assistant";
import { AssistantContext } from "../../../helpers/AssistantContext"; 
import {CheckOfflineTicketButton} from "../offline_ticket/styles/offline-tickets-styles";
class CheckTicketButton extends React.Component {

    static contextType = AssistantContext;
    
    
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <>
            
            <AssistantContext.Consumer>
                    {
                        assistant => 
                            <CheckOfflineTicketButton 
                            id={this.props.id_button}
                            size= {detectDevice()=='sberBox' ? 'l' : 's'}
                                scaleOnInteraction={false} 
                                disabled={this.props.disableButton} 
                                style={{marginTop: detectDevice()=='sberBox' ? '1.5em' : '0.0em', width:'100%'}} 
                                view="success" 
                                onClick={() => sendAction(assistant, { action_id: "CHECK_TICKET", 
                                                                                    parameters: {
                                                                                        draw: this.props.editionNumber, 
                                                                                        numbers: this.props.numbers 
                                                                                    } 
                                                                                }
                                                            )
                                                    }>
                                {this.props.buttonText}
                            </CheckOfflineTicketButton>
                    }
                </AssistantContext.Consumer>
                
            </>
        );
    }
}


export default CheckTicketButton;
import styled from "styled-components";
import {StyledCarouselItem} from '../../../../style/style'
import {
    Container, Row, Col, Card, CarouselItem, Carousel, 
    CardContent, TextBox, TextBoxBigTitle, 
    TextBoxSubTitle, Button, CarouselCol, 
    TabItem, Tabs, ParagraphText1, TextBoxTitle, TextBoxBiggerTitle,  CardParagraph1, Headline3, CarouselGridWrapper, TextField, 
} from "@sberdevices/plasma-ui";

export const CheckOfflineTicketFormParagraphText1= styled(ParagraphText1)`
    text-align: center;
    font-size: 24px;
    color: #DC283A;
    visibility: ${props=> props.visible==true ? 'visible': 'hidden'};
    @media (min-width: 140px) and (max-width: 960px) {   
        font-size: 12px;
    }  
`;

export const NameGameOnMyGames = styled(TextBoxSubTitle)`
@media (min-width: 1281px) and (max-width: 1961px) {
    font-size: 29px;
} 
`;
export const CheckOfflineTicketRow = styled(Row)`
    justify-content: 'center';
    margin: auto;

    @media (min-width: 1281px) and (max-width: 1961px) {
        width: 536px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        width: 536px;
    }
    @media (min-width: 140px) and (max-width: 960px) {   
        width: 312px;
    }  
`;

export const CheckOfflineTicketContainer = styled(Container)`
@media (min-width: 1281px) and (max-width: 1961px) {
    //background: red;
    padding-top: 10%;
    

}
@media (min-width: 961px) and (max-width: 1281px) {
    padding-top: 10%;
}
@media (min-width: 140px) and (max-width: 960px) {
    padding-top: 4em;
}
`;

export const CheckOfflineTicketTextfield = styled(TextField)`

    @media (min-width: 1281px) and (max-width: 1961px) {
        //background: red;
        &:first-child {
            border-radius: 24px;
        }
        

    }
    @media (min-width: 961px) and (max-width: 1281px) {
        &:first-child {
            border-radius: 24px;
        }
    }
    @media (min-width: 140px) and (max-width: 960px) {
        &:first-child {
            border-radius: 12px;
        }
    }
`;

export const CheckOfflineTicketImg = styled.img`
    border-radius: 50%;
    margin-right: 0.5em;
    @media (min-width: 1281px) and (max-width: 1961px) {
        height: 116px; 
        width: 116px;

    }
    @media (min-width: 961px) and (max-width: 1281px) {
        height: 80px; 
        width: 80px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        height: 58px; 
        width: 58px;
    }
`;

export const CheckOfflineTicketButton = styled(Button)`
    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size: 28px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size: 28px;
    }
    @media (min-width: 140px) and (max-width: 960px) {   
        font-size: 14px;
    }  
`;

export const CheckOfflineTicketGoToLottery = styled(Button)`
    background-color: #1F1F1F;
    margin-top: 20px;
    margin-bottom: 10px;
    @media (min-width: 1281px) and (max-width: 1961px) {
        font-size: 28px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        font-size: 28px;
    }
    @media (min-width: 140px) and (max-width: 960px) {   
        font-size: 14px;
    }  
`;

export const MyGamesContainer = styled(Container)`
background: ##000000;
    @media (min-width: 1281px) and (max-width: 1961px) {
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 10%;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 10%;
    }
    @media (min-width: 140px) and (max-width: 960px) {   
        width: 100%;
        padding-top: 50px;
        margin-bottom: 5em
    }  

`;

export const MyGamesStyledCarouselItem = styled(StyledCarouselItem)`
background: #000000;
    @media (min-width: 1281px) and (max-width: 1961px) {
       width: 17.5em;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        width: 15.5em;
    }
    @media (min-width: 140px) and (max-width: 960px) {   
        width: 100%;
    }  

`;


import {ParagraphText2} from "@sberdevices/plasma-ui";

import styled from 'styled-components';
export const WhiteSpaceTr = styled.tr`
    @media (min-width: 1281px) and (max-width: 1920px) {
        height: 9px;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        height: 5px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        height: 5px;
    }
`;

export const GreenCrossImg = styled.img`
    @media (min-width: 1281px) and (max-width: 1920px) {

        width: 32px;
        height: 32px;

    }
    @media (min-width: 961px) and (max-width: 1281px) {
        width: 28px;
        height: 28px;
    }
    @media (min-width: 140px) and (max-width: 960px) {
        width: 20px;
        height: 20px;
    }
`;

export const GreenCross = styled.div`
    color: rgba(18, 165, 87, 1); 
    position: absolute; 
    @media (min-width: 1281px) and (max-width: 1920px) {
        height: 32px;
        font-weight: 300;
    }
    @media (min-width: 961px) and (max-width: 1281px) {
        height: 28px;
        
    }
    @media (min-width: 140px) and (max-width: 960px) {
        height: 20px;
    }
`;

export const StyledParagraphText2TicketBarcode = styled(ParagraphText2)`
    color: ${props => props.colorText };
    font-weight: 500; 
    margin-left: 0.9em; 
    max-height: 33px;
    
    @media (min-width: 1281px) and (max-width: 1920px) {
        height: 20px;
        font-size: 20px;
        margin-left: 24px;
    }

    @media (min-width: 961px) and (max-width: 1281px) {
        font-size: 16px;
        margin-left: 11px;
    }
    
    @media (min-width: 140px) and (max-width: 960px) {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 10px;
        margin-left: 1.35em;
    }
`;
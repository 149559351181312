
import React from "react";
import ScreenBingo from "../bingo/ScreenBingo";
import ScreenLotto from "../lotto/ScreenLotto";
import ScreenKeno from "../lotto/ScreenKeno";
import ScreenTop3 from "../lotto/ScreenTop3";

class WaitingResultsPage extends React.Component {

    constructor(props) {
        super(props);

    }

    getScreenWithAccordingOfGame(){
      
        if([ '6x36','zp', 'gzhl','ruslotto', 'express', 'rocketbingo','bingo75'].includes(this.props.ticketsInfo.results[0].game)){
            return (
                < >
                    <ScreenBingo
                        messagePopupWasOpened={this.props.messagePopupWasOpened} 
                        touchIndex={0} 
                        buttonColor={true} 
                        waitResult={true}
                        gameInfo={this.getGameInfoTicket()} 
                        ticketWaiting={true}
                        ticketsInfo={
                            [
                                {
                                    'numbers':this.props.ticketsInfo.results[0].data.combinations[0].numbers, 
                                    'barCode':this.props.ticketsInfo.results[0].displayNumber
                                }
                            ]
                        } />
                </>
            );
        }
        if(['6x45', '7x49', '4x20', 'oxota', '5x36plus', '5x2', 'rapido', 'rapido2', 'rapido-drive', 'rapido-ultra', '1224', 'zabava', 'duel', 'dvazhdydva'].includes(this.props.ticketsInfo.results[0].game)){
            return (<>
                <ScreenLotto 
                messagePopupWasOpened={this.props.messagePopupWasOpened}
                    selectedTicketNumbers={this.getScreenLottoTicketData()} 
                    gameInfo={this.getGameInfoTicket()}
                    ticketWaiting={true}
                />
            </>)
        }
        if(['keno2'].includes(this.props.ticketsInfo.results[0].game)){
            return (
                <>
                    <ScreenKeno 
                    messagePopupWasOpened={this.props.messagePopupWasOpened}
                        selectedTicketNumbers={this.getScreenKenoTicketData()} 
                        gameInfo={this.getGameInfoTicket()}
                        ticketWaiting={true}
                    />
                </>
            )
        }
        if(['top3'].includes(this.props.ticketsInfo.results[0].game)){

            return (
                <>
                    <ScreenTop3 
                    messagePopupWasOpened={this.props.messagePopupWasOpened}
                        selectedTicketNumbers={this.getScreenTop3TicketData()} 
                        gameInfo={this.getGameInfoTicket()}
                        ticketWaiting={true}
                    />
                </>
            )
        }
    }

    getGameInfoTicket(){
        return {
            'name': this.props.ticketsInfo.results[0].game,
            'draw':{
                'id': this.props.ticketsInfo.results[0].drawInfo.id, 
                'superPrize':this.props.ticketsInfo.results[0].drawInfo.superPrize, 
                'stopSalesDate': this.props.ticketsInfo.results[0].drawInfo.dateStopSales,
                'number' : this.props.ticketsInfo.results[0].drawInfo.number,
            }
        }
    }

    getScreenKenoTicketData(){

        const kenoFirstGameMap = {'EVEN': 'even', 'ODD': 'notEven', 'FIFTY_FIFTY': 'equally'}
        return [{ 'key': 0, 'secondGame': new Set(this.props.ticketsInfo.results[0].data.combinations[0].numbers), 'threeGame': new Set(this.props.ticketsInfo.results[0].data.combinations[0].columns), 'firstGame': kenoFirstGameMap[this.props.ticketsInfo.results[0].data.combinations[0].parity], }]
    }

    getScreenLottoTicketData(){
       
        const combinations = this.props.ticketsInfo.results[0].data.combinations[0]
        return [{ 'key': 0, 'selectedNumbersFirstTicket': new Set(combinations.numbers), 'selectedNumbersSecondTicket': combinations?.extraNumbers ? new Set(combinations.extraNumbers) : new Set(combinations.secondNumbers)}]
    }

    getScreenTop3TicketData(){
        const top3GameRules = {
            1:'exactly1',
            2:'any2',
            4:'exactly3',
            5:'first2',
            6:'last2',
            7:'any3',
            8:'exactly3Plusany3',
            9:'combo',
            10:'combo',
            11:'exactly3',
            12:'exactly3',

        }
        let selectedNumbersFirstTicket = new Map()
        let numbers = this.props.ticketsInfo.results[0].data.combinations[0].numbers
       
        for(let i = 0; i < numbers.length; i++){
            selectedNumbersFirstTicket.set(i, { 'rowNumber': 9-numbers[i], 'value': numbers[i] })
        }


        return [{ 'key': 0, 'selectedNumbersFirstTicket': selectedNumbersFirstTicket, 'gameSetting': top3GameRules[this.props.ticketsInfo.results[0].data.combinations[0].type], 'sumForTicket': 0 }]
    }

    render() {
       
        return this.getScreenWithAccordingOfGame();
    }
}


export default WaitingResultsPage;
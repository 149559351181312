import { detectDevice, Spinner } from "@sberdevices/plasma-ui";
import React from "react";


import { AssistantContext } from "../helpers/AssistantContext";
import { MainMessageContainer} from './styles/messages-styles'

class Loader extends React.Component {

  static contextType = AssistantContext;

  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
    }
  }

  componentDidMount() {
    setInterval(() => {
      if (this.context.spinnerShow && !this.state.showLoader) {
        this.setState({ showLoader: true })
      }
      if (!this.context.spinnerShow && this.state.showLoader) {
        this.setState({ showLoader: false })
      }
    }, 500)
  }

  render() {
    return (
      this.state.showLoader &&
      <MainMessageContainer style={{ zIndex: 10 }}>
        <Spinner color='#12A557' size={detectDevice() == 'mobile' ? 32 : 132} />
      </MainMessageContainer>
    );
  }
}

export default Loader;

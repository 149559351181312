import { Button, TextBox, Row, Col, TextBoxTitle, detectDevice, Spinner } from "@sberdevices/plasma-ui";
import React from "react";
import styled from "styled-components";
import { addGeneralCommand, sendAction, sendText } from "../helpers/Assistant";
import { AssistantContext } from "../helpers/AssistantContext";
import { IconChevronRight, IconChevronLeft, IconClose, IconCross } from '@sberdevices/plasma-icons';
import { MainMessageContainer, ImgQr, MainImg, MainContent, MessagesButton, MainKeyboard, TextBoxTitleMessages, MessagesContentCol } from './styles/messages-styles'
import { EditionActionsPopupCross } from "./common/styles/common-elements-styles";
class Message extends React.Component {

  static contextType = AssistantContext;

  constructor(props) {
    super(props);
    //this.myRef = null;
  }

  keyPressControll(e) {
    let disallowedDirectionsOnActiveItem = document.activeElement.getAttribute('data-disallowed-directions')
    if (disallowedDirectionsOnActiveItem.includes(e.detail.dir)) {
      e.preventDefault();
      return;
    }
  }

  makeKeyboardsButtonDisalowed() {
    //window.disallowedDirections = e.target.getAttribute('data-disallowed-directions');
    document.body.addEventListener('navbeforefocus', (e) => { this.keyPressControll(e) });
  }

  componentDidMount() {
    addGeneralCommand(this.context, 'SHOW_MESSAGE', (action) => {
      this.props.toggleMessageShow(true)
      this.setStateMessageByPayload(action.payload);
    })
    addGeneralCommand(this.context, 'CLOSE_MESSAGE', () => this.closeMessage('Понятно'));
    addGeneralCommand(this.context, 'SHOW_PAY_POPUP', (action) => {
      this.props.toggleMessageShow(true)
      this.setStateMessageByPayload({
        flag: 'pay_qr',
        img: `data:image/png;base64,${action.payload.qr_code}`,
        keyboard: [],
        text: "Отсканируйте QR"
      })
      setTimeout(sendAction(this.context, { action_id: "CHECK_PAYMENT", parameters: {} }), 1000)
    })
  }

  getMobileText(flag, game_id) {
    const accauntRecovery = 'Перейдите перейдите на сайт' + `<a target="_blank" href="https://m.stoloto.ru/password-recovery">Stoloto.ru</a>` + 'для восстановления'
    const gameRulesLinks = "Ознакомьтесь с правилами на сайте" + `<a target="_blank" href=https://m.stoloto.ru/${game_id}/rules>Stoloto.ru</a>`

    return flag === 'accaunt_recovery' ? accauntRecovery : gameRulesLinks
  }

  getTextRow() {
    if (detectDevice() != 'mobile') {
      if (this.state?.flag == 'account_recovery') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Отсканируйте QR-код <br /> и перейдите по ссылке <br /> для восстановления:
        </TextBoxTitleMessages>
      }
      if (this.state?.flag === 'account_register') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Для регистрации <br /> перейдите  на сайт, <br /> отсканировав  QR-код
        </TextBoxTitleMessages>
      }
      if (this.state?.flag === 'account_blocked') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Что-то не так с вашим профилем.<br />  Пожалуйста, обратитесь в техническую поддержку: info@stoloto.ru
        </TextBoxTitleMessages>
      }
      if (this.state?.flag === 'game_rule') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Ознакомьтесь <br /> с правилами здесь:
        </TextBoxTitleMessages>
      }
      if (this.state?.flag === 'game_win_rule') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Отсканируйте QR-код  <br />  и узнайте, как <br />  получить выигрыш:
        </TextBoxTitleMessages>
      }
      if (this.state?.flag === 'logout') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Вы уверены, <br /> что хотите выйти?
        </TextBoxTitleMessages>
      }
      if (this.state?.flag === 'pay_qr') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Отсканируйте QR-код <br /> для оплаты через СБП:
        </TextBoxTitleMessages>
      }
      if (this.state?.flag === 'user_banned') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Не получилось вас авторизовать.<br /> Перейдите в свою учетную запись на сайте Столото и смените пароль
        </TextBoxTitleMessages>
      }
      return <TextBoxTitleMessages style={this.getTextPopupStyles()}>{this.state?.text}</TextBoxTitleMessages>
    } else {
      if (this.state?.flag === 'account_register') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Для регистрации перейдите <br /> на сайт <a style={{ color: '#12A557' }} target="_blank" href="https://m.stoloto.ru/registration-new/">Stoloto.ru</a>
        </TextBoxTitleMessages>
      }
      if (this.state?.flag === 'logout') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Вы уверены, <br /> что хотите выйти?
        </TextBoxTitleMessages>
      }
      if (this.state?.flag === 'account_recovery') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Для регистрации перейдите <br /> на сайт <a style={{ color: '#12A557' }} target="_blank" href="https://m.stoloto.ru/password-recovery">Stoloto.ru</a>
        </TextBoxTitleMessages>
      }
      if (this.state?.flag === 'game_rule') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Ознакомьтесь с правилами <br /> на сайте <a style={{ color: '#12A557' }} target="_blank" href={"https://m.stoloto.ru/" + this.state?.game_id + "/rules"}>Stoloto.ru</a>
        </TextBoxTitleMessages>
      }
      if (this.state?.flag === 'game_win_rule') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Перейдите на сайт <a style={{ color: '#12A557' }} target="_blank" href={"https://www.stoloto.ru/lottery-winning"}>Stoloto.ru</a>,<br /> чтобы узнать о получении <br /> выигрыша
        </TextBoxTitleMessages>
      }
      if (this.state?.flag === 'account_blocked') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Что-то не так с вашим профилем.<br />  Пожалуйста, обратитесь в техническую поддержку: info@stoloto.ru
        </TextBoxTitleMessages>
      }
      if (this.state?.flag === 'user_banned') {
        return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
          Не получилось вас авторизовать.<br /> Перейдите в свою учетную запись в <a style={{ color: '#12A557' }} target="_blank" href="https://m.stoloto.ru/password-recovery">stoloto.ru</a> и смените пароль
        </TextBoxTitleMessages>
      }
      return <TextBoxTitleMessages style={this.getTextPopupStyles()}>
        {this.state?.text}
      </TextBoxTitleMessages>
    }
  }

  setStateMessageByPayload(payload) {
    if (payload) {
      let imgUrl = payload?.img
      let text = payload.text
      let game_id = undefined
      if (payload.flag === 'game_rule') {
        imgUrl = detectDevice() != 'mobile' ? `/messages/${payload.game_id}.webp` : undefined
      }
      if (payload.flag === 'game_win_rule') {
        imgUrl = detectDevice() != 'mobile' ? `/messages/win_lottery.webp` : undefined
      }
      if (payload.flag === 'account_recovery' || payload.flag === 'user_banned') {
        imgUrl = detectDevice() != 'mobile' ? `/messages/pass_recovery.webp` : undefined
      }
      if (payload.flag === 'account_register') {
        imgUrl = detectDevice() != 'mobile' ? `/messages/registration.webp` : undefined
      }
      if (payload.flag === 'policy') {
        imgUrl = detectDevice() != 'mobile' ? `/messages/policy.webp` : undefined
      }
      // if(detectDevice()=='mobile'){
      //   text = this.getMobileText(payload.flag, payload?.game_id)
      // }

      this.setState({ text: text, keyboard: payload.keyboard, img: imgUrl, flag: payload.flag, game_id: payload?.game_id });
      window.lastFocusedElement = document.activeElement
      this.autoFocused?.focus();

      this.props.messagePopupWasOpened(true)
    }

  }

  closeMessage = (name) => {
    if (this.state?.text !== undefined) {
      if (name) {
        sendText(this.context, name)
      }
      this.props.toggleMessageShow(false)
      this.setState({ text: undefined, keyboard: [], img: undefined, flag: undefined });
      this.props.messagePopupWasOpened(false)
    } else {
      this.props.messagePopupWasOpened(false)
    }


  }

  getViewButton() {
    if (this.state.flag === 'logout') {
      return 'secondary'
    }
    return 'success'
  }

  getQrImage() {
    //create map with path of images and {this.state.img} here
    return this.state.img
  }
  getTextPopupStyles() {
    const device = detectDevice() !== 'mobile' ? 'sberBox' : 'mobile'
    const styles = {

      'game_rule':
      {
        'sberBox': {
          width: '280px',
          textAlign: 'left',
        },
        'mobile': {
          width: '240px',
        }
      },
      'game_win_rule': {
        'sberBox': {
          width: '380px',
          textAlign: 'left',
        },
        'mobile': {
          width: '240px',
        }
      },
      'logout':
      {
        'sberBox': {
          textAlign: 'center',
        }
      },
      'too_much_tries':
      {
        'sberBox': {
          width: '470px',
          textAlign: 'center',
        },
        'mobile': {
          width: '240px',
        }
      },
      'account_blocked': {
        'sberBox': {
          width: '700px',
          textAlign: 'center',
        },
        'mobile': {
          width: '240px',
        }
      },
      'account_recovery': {
        'sberBox': {
          width: '380px',
          textAlign: 'left',
        },
        'mobile': {
          width: '240px',
        }
      },
      'user_banned': {
        'sberBox': {
          width: '500px',
          textAlign: 'left',
        },
        'mobile': {
          width: '240px',
        }
      },
      'account_register': {
        'sberBox': {
          width: '320px',
          textAlign: 'left',
        }, 'mobile': {
          width: '240px',
        }
      },
      'policy': {
        'sberBox': {
          width: '320px',
          textAlign: 'left',
        }, 'mobile': {
          width: '240px',
        }
      },
      'pay_qr': {
        'sberBox': {
          width: '320px',
          textAlign: 'left',
        }, 'mobile': {
          width: '240px',
        }
      },
      'invoice_error': {
        'sberBox': {
          width: '470px',
          textAlign: 'center',
        },
        'mobile': {
          width: '240px',
        }
      }
    }
    return this.state && this.state?.flag !== undefined ? styles[this.state?.flag][device] : {}
  }
  getButtonPopupStyles() {
    const device = detectDevice() !== 'mobile' ? 'sberBox' : 'mobile'
    const styles = {
      'game_rule':
      {
        'sberBox': {
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingTop: '40px',
        },
        'mobile': {
          display: 'flex',
          alignItems: 'flex-start',
          paddingTop: '30px',
        },
      },
      'game_win_rule':
      {
        'sberBox': {
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingTop: '40px',
        },
        'mobile': {
          display: 'flex',
          alignItems: 'flex-start',
          paddingTop: '30px',
        },
      },
      'logout':
      {
        'sberBox': {
          display: 'flex',
          flexDirection: 'row',
        }
      },
      'too_much_tries': {
        'sberBox': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          paddingTop: '40px'
        },
        'mobile': {
          display: 'flex',
          alignItems: 'flex-start',
          paddingTop: '30px',
        },

      },
      'account_blocked': {
        'sberBox': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          paddingTop: '40px'
        },
        'mobile': {
          display: 'flex',
          alignItems: 'flex-start',
          paddingTop: '30px',
        },
      },
      'account_recovery': {
        'sberBox': {
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingTop: '40px',
        },
        'mobile': {
          display: 'flex',
          alignItems: 'flex-start',
          paddingTop: '30px',
        },
      },
      'user_banned': {
        'sberBox': {
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingTop: '40px',
        },
        'mobile': {
          display: 'flex',
          alignItems: 'flex-start',
          paddingTop: '30px',
        },
      },
      'account_register': {
        'sberBox': {
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingTop: '40px',
        },
        'mobile': {
          display: 'flex',
          alignItems: 'flex-start',
          paddingTop: '30px',
        },
      },
      'policy': {
        'sberBox': {
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingTop: '40px',
        },
        'mobile': {
          display: 'flex',
          alignItems: 'flex-start',
          paddingTop: '30px',
        },
      },
      'pay_qr': {
        'sberBox': {
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingTop: '40px',
        },
        'mobile': {
          display: 'flex',
          alignItems: 'flex-start',
          paddingTop: '30px',
        },
      },
      'invoice_error': {
        'sberBox': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          paddingTop: '40px'
        },
        'mobile': {
          display: 'flex',
          alignItems: 'flex-start',
          paddingTop: '30px',
        },
      },

    }
    return this.state && this.state?.flag !== undefined ? styles[this.state?.flag][device] : {}
  }
  getContentRowStyles() {
    const device = detectDevice() !== 'mobile' ? 'sberBox' : 'mobile'
    const styles = {
      'game_rule':
      {
        'sberBox': {
          paddingTop: '85px',
          paddingLeft: '150px',
          paddingRight: '150px',
          paddingBottom: '105px',
        },

        'mobile': {
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingBottom: '40px',
          paddingTop: '30px',
        }
      },
      'game_win_rule':
      {
        'sberBox': {
          paddingTop: '85px',
          paddingLeft: '150px',
          paddingRight: '150px',
          paddingBottom: '105px',
        },

        'mobile': {
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingBottom: '40px',
          paddingTop: '30px',
        }
      },
      'logout':
      {
        'sberBox': {
          paddingTop: '85px',
          paddingLeft: '170px',
          paddingRight: '170px',
          paddingBottom: '105px',
        },
        'mobile': {
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingBottom: '75px',
          paddingTop: '50px',
        }
      },
      'too_much_tries':
      {
        'sberBox': {
          paddingTop: '65px',
          paddingLeft: '100px',
          paddingRight: '100px',
          paddingBottom: '65px',
        },
        'mobile': {
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingBottom: '40px',
          paddingTop: '40px',
        }
      },
      'account_blocked': {
        'sberBox': {
          paddingTop: '85px',
          paddingLeft: '50px',
          paddingRight: '50px',
          paddingBottom: '85px',
        },
        'mobile': {
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingBottom: '40px',
          paddingTop: '40px',
        }
      },
      'account_recovery': {
        'sberBox': {
          paddingTop: '85px',
          paddingLeft: '130px',
          paddingRight: '130px',
          paddingBottom: '85px',
        },
        'mobile': {
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingBottom: '40px',
          paddingTop: '40px',
        }
      },
      'user_banned': {
        'sberBox': {
          paddingTop: '85px',
          paddingLeft: '110px',
          paddingRight: '110px',
          paddingBottom: '85px',
        },
        'mobile': {
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingBottom: '40px',
          paddingTop: '40px',
        }
      },
      'account_register': {
        'sberBox': {
          paddingTop: '85px',
          paddingLeft: '150px',
          paddingRight: '150px',
          paddingBottom: '105px',
        },
        'mobile': {
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingBottom: '40px',
          paddingTop: '40px',
        }
      },
      'policy': {
        'sberBox': {
          paddingTop: '85px',
          paddingLeft: '150px',
          paddingRight: '150px',
          paddingBottom: '105px',
        },
        'mobile': {
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingBottom: '40px',
          paddingTop: '40px',
        }
      },
      'pay_qr': {
        'sberBox': {
          paddingTop: '85px',
          paddingLeft: '150px',
          paddingRight: '150px',
          paddingBottom: '105px',
        },
        'mobile': {
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingBottom: '40px',
          paddingTop: '40px',
        }
      },
      'invoice_error': {
        'sberBox': {
          paddingTop: '85px',
          paddingLeft: '150px',
          paddingRight: '150px',
          paddingBottom: '105px',
        },
        'mobile': {
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingBottom: '40px',
          paddingTop: '40px',
        }
      },
    }
    return this.state && this.state?.flag ? styles[this.state?.flag][device] : {}
  }

  render() {
    return (
      <MainMessageContainer style={this.state?.text ? { zIndex: 10 } : { display: 'none', zIndex: 8 }}>
        <MainContent >
          <Row
            tabIndex={-1}
            style={
              {
                zIndex: 12,
                maxHeight: '40px',
                width: '100%',
                paddingTop: '10px',
                paddingRight: '10px',
                justifyContent: 'flex-end',
              }
            }  >
            <EditionActionsPopupCross
              size={'s'}
              square={true}
              ref={(but) => { if (this.state?.flag === 'pay_qr') this.autoFocused = but; }}
              onClick={(e) => {
                detectDevice() !== "sberBox" &&
                  this.closeMessage('Понятно')
              }
              }

              onKeyUp={(e) => {
                e.key === "Enter" &&
                  this.closeMessage('Понятно')
              }
              }


              tabIndex={0}
              className="message-popup"
            >
              <IconCross ></IconCross>
            </EditionActionsPopupCross>
          </Row>
          {this.state?.flag === 'pay_qr' ?
            <Row style={this.getContentRowStyles()}>
              <Col>
                <MainImg>
                  <ImgQr src={this.getQrImage()} alt="QR" />
                </MainImg>
                <TextBox >Оплатите через СБП</TextBox>
              </Col>
              <Col style={{display: "grid", justifyItems: "center", alignContent: "center"}}>
                <Spinner color="green" />
                <TextBox >Ожидание<br/>оплаты</TextBox>
              </Col>
            </Row>
            :
            <Row style={this.getContentRowStyles()}>
              <MessagesContentCol>
                <TextBox >{this.getTextRow()}</TextBox>
                <MainKeyboard style={this.getButtonPopupStyles()} >
                  {this.state?.keyboard.map((name, i) => (
                    <MessagesButton
                      className="message-popup"
                      ref={(input) => { this.autoFocused = input; }}
                      size={'s'}
                      tabIndex={0}
                      text={name}
                      view={this.getViewButton()}
                      key={i}

                      onClick={(e) => {
                        detectDevice() !== "sberBox" &&
                          this.closeMessage(name);
                        //sendText(this.context, name);
                      }
                      }

                      onKeyUp={(e) => {
                        e.key === "Enter" &&
                          this.closeMessage(name)
                      }

                        //sendText(this.context, name)

                      }

                    />
                  ))}
                </MainKeyboard>
              </MessagesContentCol>
              {this.state?.img ? (
                <Col>
                  <MainImg>
                    <ImgQr src={this.getQrImage()} alt="QR" />
                  </MainImg>
                </Col>

              ) : null}
            </Row>
          }
        </MainContent>
      </MainMessageContainer>
    );
  }
}

export default Message;

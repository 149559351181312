import { Button, Container, Row, detectDevice } from "@sberdevices/plasma-ui";
import React from "react";
import { sendText, setPageCommands } from "../../helpers/Assistant";
import { AssistantContext } from "../../helpers/AssistantContext";
import { StolotoTabs } from "../StolotoTabs"
import EditionActions from "../common/EditionActions"
import ButtonsActions from "../common/ButtonsActions"
import TicketsCarousel from "../common/TicketsCarousel"
import { LeftContainer, RightContainer, LeftShadow, RightShadow, ButtonsRow } from "../../style/style"
import { formatMoney } from "../../helpers/TextUtils";

class ScreenBingo extends React.Component {
    static contextType = AssistantContext;
    constructor(props) {
        super(props);
        this.state = {
            //payPopupOpen: false,
            carouselIndex: 0,
            screenMobile: window.screen.width < 450 ? true : false,
            tickets: new Set(),
            gameInfo: this.props.gameInfo,
            ticketsInfo: this.getChunkedTicketsInfo(this.props.ticketsInfo),
            currentDrawNumber: this.props.gameInfo.draw.number,
            payPopupWasOpened: false,
            activeDrawInfo: this.getDrawData(this.props.gameInfo)[0]
        }
    }
    // onPayPopupOpenClose=(statePopup)=>{
    //     this.setState({payPopupOpen: statePopup})
    // }
    componentDidMount() {
        this.setPageOverflow('scroll')
        setPageCommands(this.context, {
            SCREEN_BINGO: (action)=>{
                this.setState({ ticketsInfo: this.getChunkedTicketsInfo(action.payload.lottery.tickets), gameInfo: action.payload.lottery.GameInfo, tickets: new Set()})
            },
            NEW_BINGO: (action) => {
                this.setState({ ticketsInfo: this.getChunkedTicketsInfo(action.payload.lottery.tickets) })
            }, 
            DRAW_CHANGE: (action) => {
                this.setState(action.payload)
            },
            SHOW_DRAWS_POPUP:  ()=>{
                let e = document.createEvent("HTMLEvents");
                 e.initEvent("keyup", true, true);
                 e.keyCode = 13;
                 //e.target = document.getElementById('open_pay_papup_button');
                 document.getElementById('open_draws_papup_button').dispatchEvent(e)
                 document.getElementById('open_draws_papup_button').click()
             },
             FINISH_PAY: ()=>{
                let e = document.createEvent("HTMLEvents");
                e.initEvent("keyup", true, true);
                e.keyCode = 13;
                if(document.getElementById('pay_popup_button')!=null ){
                    document.getElementById('pay_popup_button').dispatchEvent(e)
                    document.getElementById('pay_popup_button').click()
                }
             },
            START_PAY: ()=>{
                this.setState({payPopupWasOpened: true})
                // let e = document.createEvent("HTMLEvents");
                // e.initEvent("keyup", true, true);
                // e.keyCode = 13;

                //     if(document.getElementById('open_pay_papup_button')!=null && !document.getElementById('open_pay_papup_button').disabled){
                //         document.getElementById('open_pay_papup_button').dispatchEvent(e)
                //         document.getElementById('open_pay_papup_button').click()
                //     }
                
            },
            NULL: ()=>{},
        });
    }

    componentWillUnmount(){
        setPageCommands(this.context, {});
        this.setPageOverflow('hidden')
    }

    setPageOverflow(overflowValue){
        if(detectDevice()=='mobile'){
            document.getElementsByTagName('body')[0].style.overflowY = overflowValue
            document.getElementsByTagName('html')[0].style.overflowY = overflowValue
        }
    }

    setActiveDraw(activeDrawInfo) {
        this.setState({ activeDrawInfo })
    }

    getChunkedTicketsInfo(ticketsInfo) {
        let chunkSize = window.screen.width < 450 ? 1 : 2;
        let newArray = []
        for (let i = 0; i < ticketsInfo.length; i ++) {
            if(!ticketsInfo[i].hasOwnProperty('barCode')){
                ticketsInfo[i].barCode = i+1
            }
        }
        for (let i = 0; i < ticketsInfo.length; i += chunkSize) {
            const chunk = ticketsInfo.slice(i, i + chunkSize);         
            newArray.push(chunk)
        }
        return newArray
    }

    getOneRowCarousel() {
        let editionInfo=this.getDrawData()
        let activeDrawInfo = editionInfo[0]
        return (<><Container style={{ display: 'flex', alignItems: 'center', marginTop: '65px' }}>
            <EditionActions
                messagePopupWasOpenedFunction = {this.props.messagePopupWasOpenedFunction}
                messagePopupWasOpened={this.props.messagePopupWasOpened}
                editionInfo={editionInfo}
                activeDrawInfo={activeDrawInfo}
                drawInfo={this.state.gameInfo}
                gameName={this.state.gameInfo.name}
                drawSuperprise={this.state.gameInfo.draw.superPrize}
                drawNumber={this.state.gameInfo.draw.number}
                drawId={this.state.gameInfo.draw.id}
                drawDate={this.state.gameInfo.draw.date}
                tickets={this.state.tickets}
                editDrawNumber={this.editDrawNumber}
                ticketWaiting={this.props?.ticketWaiting}
                updateActiveDraw={(activeDraw) => {this.setActiveDraw(activeDraw)}}

            />
            <Container style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                <TicketsCarousel 
                    messagePopupWasOpened={this.props.messagePopupWasOpened}
                    ticketWaiting={this.props?.ticketWaiting}
                    addActiveTicketBarcode={this.addActiveTicketBarcode} 
                    gameName={this.state.gameInfo.name} 
                    ticketsInfo={this.state.ticketsInfo} 
                    tickets={this.state.tickets}
                />
                <ButtonsRow>
                {this.getActiveButton(editionInfo)}
                </ButtonsRow>
                
            </Container>
        </Container>


        </>)
    }

    addActiveTicketBarcode = (tickets) => {
        this.setState({ tickets: tickets })
    }

    getActiveButton(editionInfo) {
        return this.getActionsButton(editionInfo)
    }
    getBackButton() {
        return <Button> Назад </Button>
    }
    getActionsButton(editionInfo) {
        return <ButtonsActions
            currentDrawNumber={this.state.currentDrawNumber}
       // onPayPopupOpenClose={this.onPayPopupOpenClose}
            messagePopupWasOpenedFunction = {this.props.messagePopupWasOpenedFunction}
            messagePopupWasOpened={this.props.messagePopupWasOpened}
            gameInfo={this.state.gameInfo}
            ticketsQty={this.state.tickets.size}
            tickets={this.state.ticketsInfo.flat().filter(t => this.state.tickets.has(t.barCode))}
            betCost={this.state.gameInfo.draw.betCost}
            actionButton={this.generateNew}
            waitingPage={this.props?.ticketWaiting}
            messageShow={this.props.messageShow}
            editionInfo={editionInfo}
            activeDrawInfo={this.state.activeDrawInfo}
            payPopupWasOpened={this.state.payPopupWasOpened}
            payPopupClosed={this.payPopupClosed}
        />
    }
    payPopupClosed=()=>{
        this.setState({payPopupWasOpened: false})

    }
    getDrawData(gameInfo) {
        if (!gameInfo) {
            gameInfo = this.state.gameInfo
        }
        let drawDataList = []
        if (new Date(gameInfo.draw.stopSalesDate) > new Date() || this.props.ticketWaiting){
            drawDataList.push(
                {
                    'drawSuperprise':gameInfo.draw.superPrize, 
                    'drawNumber': gameInfo.draw.number, 
                    'drawId': gameInfo.draw.id,
                    'drawDate': gameInfo.draw.stopSalesDate 
                }
            )
        }
        if (gameInfo.nextDraw &&
            new Date(gameInfo.nextDraw.stopSalesDate) > new Date()
            ) {
            drawDataList.push(
                {
                    'drawSuperprise':gameInfo.nextDraw.superPrize, 
                    'drawNumber': gameInfo.nextDraw.number, 
                    'drawId': gameInfo.nextDraw.id,
                    'drawDate': gameInfo.nextDraw.stopSalesDate 
                }
            )
        }
        if(gameInfo.specialDraws!=null){
            gameInfo.specialDraws.map(
                (curDraw, i) => drawDataList.push(
                    {
                        'drawSuperprise':curDraw.superPrize, 
                        'drawNumber': curDraw.number, 
                        'drawId': curDraw.id,
                        'drawDate': curDraw.stopSalesDate 
                    }
                )
            )
        }
        
        return drawDataList
    }
    getDoubleRowCarousel() {
        let editionInfo=this.getDrawData()
        let activeDrawInfo = editionInfo[0]     
        
        return (
            <>
                <LeftContainer>
                    <EditionActions
                        messagePopupWasOpenedFunction = {this.props.messagePopupWasOpenedFunction}
                        messagePopupWasOpened={this.props.messagePopupWasOpened}
                        editionInfo={editionInfo}
                        activeDrawInfo={activeDrawInfo}
                        drawInfo={this.state.gameInfo}
                        gameName={this.state.gameInfo.name}
                        drawSuperprise={this.state.gameInfo.draw.superPrize}
                        drawNumber={this.state.currentDrawNumber}
                        drawId={this.state.gameInfo.draw.id}
                        drawDate={this.state.gameInfo.draw.date}
                        tickets={this.state.tickets}
                        editDrawNumber={this.editDrawNumber}
                        messageShow={this.props.messageShow}
                        ticketWaiting={this.props?.ticketWaiting}
                    />
                    <ButtonsRow>
                    {this.getActiveButton(editionInfo)}
                    </ButtonsRow>
                    
                </LeftContainer>
                <LeftShadow></LeftShadow>
                <RightContainer className='khkhkh' isWaiting={this.props.ticketWaiting}>
                    <TicketsCarousel
                        messagePopupWasOpened={this.props.messagePopupWasOpened}
                        ticketWaiting={this.props?.ticketWaiting}
                        addActiveTicketBarcode={this.addActiveTicketBarcode}
                        tickets={this.state.tickets}
                        gameName={this.state.gameInfo.name}
                        ticketsInfo={this.state.ticketsInfo}
                        messageShow={this.props.messageShow}
                    />
                </RightContainer>
                <RightShadow></RightShadow>
            </>
        )
    }

    editDrawNumber = (currentDrawNumber) => {
        this.setState({ currentDrawNumber: currentDrawNumber })
    }

    generateNew = (assistant) => {
        this.setState({ tickets: new Set() })
        sendText(assistant, 'сгенерировать новые')
        
    }

    getCarousel() {
        return !this.state.screenMobile ? this.getDoubleRowCarousel() : this.getOneRowCarousel()
    }


    getTopMarginOfTicketForm(){
        if(detectDevice()=='sberBox'){
            if(this.props.gameName=='1224' || this.props.gameName == 'zabava'){
                return '15%'
            }
            if(!['top3', 'keno2'].includes(this.props.gameName)){
                return '10%'
            }
            return '10%'
        }
        if(detectDevice()=='sberPortal'){
            if(this.props.gameName=='1224' || this.props.gameName == 'zabava'){
                return '7%'
            }
            if(!['top3', 'keno2'].includes(this.props.gameName)){
                return '8%'
            }
            return '7.5%'
        }

    }
    render() {
        const sumToPay = this.state.tickets.size * this.state.gameInfo.draw.betCost
        
        return (
            <>
                

                    <StolotoTabs messagePopupWasOpened={this.props.messagePopupWasOpened} messageShow={this.props.messageShow}  activeTab='lotteries' showPayText={sumToPay > 0} payMoney={formatMoney(sumToPay)} />
                

                <Row style={{marginTop: this.getTopMarginOfTicketForm()}}>{this.getCarousel()}</Row>

            </>


        );
    }
}

export default ScreenBingo;